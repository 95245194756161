import { Typography, Box, Divider, Skeleton } from "@mui/material";
import { fCurrency } from "../utils/formatNumber";
import { LedgerTransaction } from "./types";

const LedgerSummary = ({
  ledgerTypeHeader,
  total,
  transactions
}: {
  ledgerTypeHeader: string;
  total: number | null;
  transactions: LedgerTransaction[];
}) => {
  return (
    <>
      <Typography variant="subtitle2">{ledgerTypeHeader}</Typography>

      {transactions.map((transaction, index) => (
        <Box key={index} display="flex" justifyContent="space-between">
          <Typography variant="body2" color="#637381">
            {transaction.name}:
          </Typography>
          <Typography variant="body2" color="#637381">
            <Box component="span" textAlign="right">
              {fCurrency(transaction.amount)}
            </Box>
          </Typography>
        </Box>
      ))}

      <Divider />

      <Typography variant="body2" color="#637381" textAlign="right">
        Total: {total ? fCurrency(total) : ""}
      </Typography>
    </>
  );
};

export default LedgerSummary;
