import { Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineSeparator } from "@mui/lab";
import TimelineOppositeContent, { timelineOppositeContentClasses } from "@mui/lab/TimelineOppositeContent";
import { Stack, Typography } from "@mui/material";
import { CustomAvatar } from "../../components/custom-avatar";
import { DashboardNote } from "../types";

const NotesTimeline = ({ notes = [] }: { notes: DashboardNote[] }) => {
  return (
    <>
      {notes?.length > 0 && (
        <Stack direction="column" spacing={2}>
          <Typography variant="subtitle1">History</Typography>
          <Timeline
            sx={{
              [`& .${timelineOppositeContentClasses.root}`]: {
                flex: 0.4
              }
            }}
          >
            {notes?.map((n) => {
              return (
                <TimelineItem key={n.id}>
                  <TimelineOppositeContent color="textSecondary" width={300}>
                    <Stack direction="column">
                      <Typography variant="caption">{n.createdByUser}</Typography>
                      <Typography variant="caption">
                        {new Date(n.dateCreated).toLocaleDateString()}{" "}
                        {new Date(n.dateCreated).toLocaleTimeString(undefined, {
                          hour: "numeric",
                          minute: "numeric"
                        })}
                      </Typography>
                    </Stack>
                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineDot variant="outlined">
                      <CustomAvatar
                        alt={n.createdByUser}
                        name={n.createdByUser}
                        sx={{ width: 22, height: 22, fontSize: 12 }}
                      />
                    </TimelineDot>
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent>
                    <Typography variant="body2" sx={{ whiteSpace: "pre-wrap" }}>
                      {n.text}
                    </Typography>
                  </TimelineContent>
                </TimelineItem>
              );
            })}
          </Timeline>
        </Stack>
      )}
    </>
  );
};

export default NotesTimeline;
