import { useState } from "react";
import { Box, Typography, Link, Popover } from "@mui/material";
import { BasicTask } from "./types";

const RequirementInfo = ({ task }: { task: BasicTask }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const popoverOpen = Boolean(anchorEl);

  return (
    <Box minWidth={180}>
      <Typography variant="body2" display="inline-block">
        created by{" "}
      </Typography>{" "}
      <Link sx={{ cursor: "pointer" }} display="inline-block" onClick={handlePopoverClick}>
        Req: #{task.selectRequirementNumber} {task.selectRequirementCode}
      </Link>
      <Popover
        id="click-popover"
        open={popoverOpen}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
        onClose={() => setAnchorEl(null)}
      >
        <Box sx={{ p: 2, bgcolor: "#212b36", color: "#fff" }} width={360}>
          <Typography variant="body2">{task.originalSelectRequirementText}</Typography>
        </Box>
      </Popover>
    </Box>
  );
};

export default RequirementInfo;
