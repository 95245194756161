import React, { useContext, useEffect, useState } from "react";
import { Box, Button, Grid, Stack, TextField, Typography } from "@mui/material";
import ContactTypeDropdown from "./ContactTypeDropdown";
import axios from "axios";
import { Contact } from "../types/Contact";
import { ContactType } from "../types/ContactType";
import DebouncedAutoComplete from "../../components/DebouncedAutoComplete";
import { OtherContactImg } from "./ContactCardNew";
import { PropertyInfo } from "../types/Property";
import { getDefaultPropertyInfoObj } from "../../utils/property";
import { OrderContext } from "../../contexts/OrderDataContext";

export default function OtherContact({
  contact,
  setContact,
  setContactType,
  contactType,
  errorMode,
  resetSearchedContact,
  contactList,
  setContactList
}: {
  contact: Contact;
  setContact: React.Dispatch<React.SetStateAction<Contact>>;
  setContactType: React.Dispatch<React.SetStateAction<ContactType | null>>;
  contactType: ContactType;
  errorMode?: boolean;
  resetSearchedContact: () => void;
  contactList: Contact[];
  setContactList: React.Dispatch<React.SetStateAction<Contact[]>>;
}) {
  const { onContactSearchTextChanged } = useContext(OrderContext);
  const [otherContactType, setOtherContactType] = useState("");
  const [selectedAddress, setSelectedAddress] = useState<PropertyInfo | null>(null);
  const [addressResults, setAddressResults] = useState<PropertyInfo[]>([]);

  const searchAddress = async (text: string) => {
    if (!text) {
      return;
    }
    const { data } = await axios.get(`/api/property/search?searchText=${encodeURIComponent(text)}`);
    return data.suggestions.map(
      (d: any) =>
        ({
          entries: d.entries,
          aptNo: d.secondary,
          address: d.street_line,
          formattedAddress: d.formattedStreet_line,
          city: d.city,
          state: d.state,
          zipCode: d.zipcode
        } as PropertyInfo)
    );
  };

  useEffect(() => {
    if (contact) {
      setContact({
        ...contact,
        address1: selectedAddress?.formattedAddress || "",
        address2: selectedAddress?.aptNo || "",
        city: selectedAddress?.city || "",
        state: selectedAddress?.state || "",
        zip: selectedAddress?.zipCode || ""
      } as Contact);
    }
  }, [selectedAddress]);

  const freeTypedOption = "I dont want to use these results, keep what I typed.";

  const clearContactList = () => {
    setContactList([]);
  };

  const contactOptionsList = contactList.length > 0 ? [{ name: freeTypedOption } as Contact, ...contactList] : [];

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={contactType?.name !== "Other" ? 12 : 6}>
          <Stack direction="row" justifyContent="center">
            <Box width={contactType?.name !== "Other" ? "50%" : "100%"}>
              <ContactTypeDropdown
                setContactType={setContactType}
                contactType={contactType}
                setContact={setContact}
                contact={contact}
                errorMode={errorMode}
                resetSearchedContact={resetSearchedContact}
              />
            </Box>
          </Stack>
        </Grid>
        {(contactType?.name === "Other" || otherContactType) && (
          <Grid item xs={6}>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Please describe type of other"
              label="Other Type"
              size="small"
              value={otherContactType || ""}
              onChange={(e) => {
                setOtherContactType(e.target.value);
              }}
              onBlur={(e) =>
                setContact({
                  ...contact,
                  otherContactType: e.target.value
                } as Contact)
              }
            />
          </Grid>
        )}
        <Grid item xs={6}>
          <Stack direction="row" justifyContent="center">
            <OtherContactImg />
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <Stack direction="column" spacing={2}>
            <DebouncedAutoComplete
              freeSolo
              size="small"
              value={contact}
              fullWidth={true}
              options={contactOptionsList}
              getOptionsLabel={(option) => option?.name || ""}
              onSelectedOptionChanged={(_e, c) => {
                if (typeof c === "object") {
                  setContact({
                    ...c,
                    contactType: contact.contactType,
                    type: contact?.type,
                    represents: contact?.represents
                  } as Contact);
                }
              }}
              renderOption={(props, option) => {
                if (option?.name === freeTypedOption) {
                  return <Button onClick={clearContactList}>{freeTypedOption}</Button>;
                } else {
                  return (
                    <li {...props} key={option?.lookupCode}>
                      <div style={{ width: "100%" }}>
                        <div>
                          <Typography sx={{ display: "inline-block" }} variant="subtitle2">
                            {`${option?.name || ""}`}
                          </Typography>
                        </div>
                        {option?.email && (
                          <div>
                            <Typography sx={{ display: "inline-block" }}>{`${option?.email || ""}`}</Typography>
                          </div>
                        )}
                      </div>
                    </li>
                  );
                }
              }}
              textboxLabel="Name"
              textboxPlaceholder="Name"
              onDebouncedTextChanged={(text) => onContactSearchTextChanged(text, setContactList)}
              additionalOnChange={(text) => setContact({ ...contact, name: text })}
            />
            <TextField
              fullWidth
              variant="outlined"
              label="Company / Payee Name"
              size="small"
              type="text"
              value={contact?.payeeName || contact?.name || ""}
              onChange={(e) => setContact({ ...contact, payeeName: e.target.value })}
            />
            <TextField
              fullWidth
              variant="outlined"
              label="Email"
              size="small"
              type="text"
              value={contact?.email || ""}
              onChange={(e) => setContact({ ...contact, email: e.target.value })}
            />
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <DebouncedAutoComplete
            freeSolo
            value={
              selectedAddress || {
                ...getDefaultPropertyInfoObj(),
                address: contact?.address1
              }
            }
            options={addressResults}
            getOptionsLabel={(option: PropertyInfo) => option?.formattedAddress || contact?.address1 || ""}
            renderOption={(props, option, { selected }) => (
              <li
                {...props}
                key={`${option?.formattedAddress}${(option as PropertyInfo)?.entries}${option?.aptNo}${option?.city}${
                  option?.state
                }${option?.zipCode}`}
              >
                <div style={{ width: "100%" }}>
                  <div>
                    <Typography sx={{ display: "inline-block" }} variant="subtitle2">
                      {option?.entries && option?.entries > 2
                        ? `${option?.formattedAddress} ${option?.aptNo ? " " + option?.aptNo : ""} (${
                            option?.entries
                          } more entries) ${option?.city}, ${option?.state} ${option?.zipCode}`
                        : `${option?.formattedAddress}${option?.aptNo ? " " + option?.aptNo : ""}, ${option?.city}, ${
                            option?.state
                          } ${option?.zipCode}`}
                    </Typography>
                  </div>
                </div>
              </li>
            )}
            onSelectedOptionChanged={(e, value) => {
              if (typeof value === "object" && value) {
                setSelectedAddress(value);
              }
            }}
            textboxLabel="Address Quick Entry"
            textboxPlaceholder="Start typing an address..."
            additionalOnChange={(text) => setContact({ ...contact, address1: text } as Contact)}
            onDebouncedTextChanged={async (text) => {
              const results = await searchAddress(text);
              setAddressResults(results);
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            variant="outlined"
            label="Address 2"
            size="small"
            value={contact?.address2 || ""}
            onChange={(e) => setContact({ ...contact, address2: e.target.value })}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            variant="outlined"
            label="City"
            size="small"
            type="text"
            fullWidth
            value={contact?.city || ""}
            onChange={(e) => setContact({ ...contact, city: e.target.value })}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            fullWidth
            variant="outlined"
            label="State"
            size="small"
            type="text"
            value={contact?.state || ""}
            onChange={(e) => setContact({ ...contact, state: e.target.value })}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            fullWidth
            variant="outlined"
            label="Zip"
            size="small"
            type="text"
            value={contact?.zip || ""}
            onChange={(e) => setContact({ ...contact, zip: e.target.value })}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Phone"
            size="small"
            fullWidth
            type="text"
            value={contact?.phone || ""}
            onChange={(e) => setContact({ ...contact, phone: e.target.value })}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Cell"
            size="small"
            type="text"
            fullWidth
            value={contact?.cell || ""}
            onChange={(e) => setContact({ ...contact, cell: e.target.value })}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Fax"
            size="small"
            type="text"
            fullWidth
            value={contact?.fax || ""}
            onChange={(e) => setContact({ ...contact, fax: e.target.value })}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="License Number"
            size="small"
            type="text"
            fullWidth
            value={contact?.licenseNumber || ""}
            onChange={(e) => setContact({ ...contact, licenseNumber: e.target.value })}
          />
        </Grid>
      </Grid>
    </>
  );
}
