import { Box, Button, Container, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import { Helmet } from "react-helmet-async";
import { MaterialReactTable } from "material-react-table";
import { columns } from "./columns";
import { useContext, useEffect, useState } from "react";
import { EscrowLedgersContext } from "./EscrowLedgersContext";
import Actions from "./Actions";
import { UserContext } from "../../contexts/UserContext";
import { StringParam, useQueryParam } from "use-query-params";
import { InternalUser } from "../../types/app";
import axios from "axios";
import { DashboardReportType } from "../../checks/types";
import NotesModal from "../../checks/checkNotes/NotesModal";
import { EscrowLedger } from "./types";
import { NotesColumn } from "../NotesColumn";
import Iconify from "../../../minimals/components/iconify";
import { exportTableToCsv } from "../../taskUtils";
import ClosingChecklistEscrow from "../../taskManagement/ClosingChecklistEscrow";

export default function EscrowLedgers() {
  const { escrowLedgers, loading, assignedToMe, setAssignedToMe, fetchEscrowLedgers, setEscrowLedgers, tableRef } =
    useContext(EscrowLedgersContext);
  const { user } = useContext(UserContext);
  const [assignedToId] = useQueryParam("assignedtoid", StringParam);
  const [assignedToUser, setAssignedToUser] = useState<InternalUser | null>(null);
  const [selectedOrder, setSelectedOrder] = useState<EscrowLedger | null>(null);
  const [enableOrdering, setEnableOrdering] = useState(false);
  const [openClosingChecklistEscrow, setOpenClosingChecklistEscrow] = useState<boolean>(false);

  const isUnassignedOnly = Number(assignedToId) == -1;

  const getAndSetAssignedToUser = async () => {
    if (!assignedToId || isUnassignedOnly) {
      return;
    }
    const { data } = await axios.get<InternalUser>(
      `/api/clientphonebook/internalusers/getInternalUserById?id=${assignedToId}`
    );
    setAssignedToUser(data);
  };
  const toggleColumnOrdering = () => {
    setEnableOrdering(!enableOrdering);
  };

  const filteredEscrowLedgers = assignedToMe
    ? escrowLedgers.filter((o) => {
        if (isUnassignedOnly) {
          return !o.assignedToUser;
        }
        if (assignedToId) {
          return assignedToId == String(o.assignedToUser?.id || "");
        }
        return user?.id && user.id == o.assignedToUser?.id; // || managerDepartments.includes(o.department);
      })
    : escrowLedgers;

  useEffect(() => {
    getAndSetAssignedToUser();
  }, [assignedToId]);

  const onRowsDeselected = () => {
    if (tableRef.current) {
      tableRef.current.toggleAllRowsSelected(false);
    }
  };

  const columnsWithNotes = NotesColumn<EscrowLedger>(columns, setSelectedOrder);

  return (
    <>
      <Helmet>
        <title>Escrow Ledgers | TitleQ</title>
      </Helmet>

      <Container maxWidth="xl">
        <Stack direction="row" alignItems="center" spacing={2}>
          <Typography variant="h4" marginBottom={2}>
            Escrow Ledgers
            {isUnassignedOnly && " - Unassigned"}
            {assignedToUser && ` - Assigned to ${assignedToUser.firstName} ${assignedToUser.lastName}`}
          </Typography>
          <Button
            variant="outlined"
            onClick={() => setOpenClosingChecklistEscrow(true)}
            size="small"
            sx={{ marginBottom: 1 }}
          >
            Transfer Escrow Funds
          </Button>
        </Stack>
        <MaterialReactTable
          columns={columnsWithNotes}
          data={filteredEscrowLedgers}
          enableGlobalFilter
          enableFacetedValues
          enableRowSelection
          enableColumnOrdering={enableOrdering}
          initialState={{
            density: "compact",
            pagination: { pageIndex: 0, pageSize: 30 }
          }}
          state={{
            showSkeletons: loading
          }}
          muiTableBodyProps={{
            sx: {
              "& .MuiTableRow-root:hover .MuiTableCell-root": {
                backgroundColor: "inherit !important"
              }
            }
          }}
          muiTableBodyRowProps={({ row }) => {
            // Apply zebra striping styles to alternate rows
            return {
              style: row.index % 2 === 0 ? { backgroundColor: "#f4f4f4" } : {} // Change the background color as needed
            };
          }}
          renderTopToolbarCustomActions={({ table }) => {
            return (
              <>
                <Box sx={{ flexGrow: 1, position: "relative" }}>
                  <Stack direction="row" spacing={2} alignItems="center">
                    <Actions
                      assignedToMe={assignedToMe}
                      setAssignedToMe={setAssignedToMe}
                      count={filteredEscrowLedgers.length}
                      hideAssignedToMeOptions={true}
                      table={table}
                      fetchTableData={() => fetchEscrowLedgers(true)}
                      reportType={DashboardReportType.EscrowOverdue}
                      onRowsDeselected={onRowsDeselected}
                    />
                    <Box sx={{ position: "absolute", right: 0 }}>
                      <Tooltip title="Column ordering">
                        <IconButton onClick={toggleColumnOrdering}>
                          <Iconify icon="mdi:reorder-vertical" />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Export to csv">
                        <IconButton onClick={() => exportTableToCsv(table, "export.csv")}>
                          <Iconify icon="eva:download-fill" />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </Stack>
                </Box>
              </>
            );
          }}
        />
      </Container>
      {!!selectedOrder && (
        <NotesModal
          isOpen
          nameBeforeUpdate={selectedOrder?.assignedToUser?.fullName || ""}
          emailBeforeUpdate={selectedOrder?.assignedToUser?.email || ""}
          entity={selectedOrder}
          setEntity={setSelectedOrder}
          setEntityTable={setEscrowLedgers}
          refetchData={() => fetchEscrowLedgers(false)}
          reportType={DashboardReportType.EscrowOverdue}
        />
      )}
      {openClosingChecklistEscrow && (
        <ClosingChecklistEscrow
          onClose={() => setOpenClosingChecklistEscrow(false)}
          refresh={() => fetchEscrowLedgers(true)}
          editableOrderNumber
        />
      )}
    </>
  );
}
