  import { useState, useEffect } from "react";
  import {
  Stack,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Grid,
  CircularProgress,
  MenuItem, InputAdornment, IconButton
  } from "@mui/material";
  import { useSearchParams } from "react-router-dom";
  import axios from "axios";
  import { useOrderInfo } from "../shared/orderInfo/OrderInfoContext";
  import LedgerSummary from "./LedgerSummary";
  import LedgerLoader from "./orderInfo/LedgerLoader";
  import { Icon } from '@iconify/react';
  import Iconify from "../../minimals/components/iconify";
  
  type RecordingLedgerInfo = {
    recordingFees: number | null;
    transferTax: number | null;
    mortgageTax: number | null;
    withholdingTax: number | null;
  };
  
  type LedgerTransaction = {
    id: string;
    status: string;
    amount: number;
    name: string;
    payorPayee: string;
    type: string;
    typeDescription: string;
    isDebit: boolean;
    referenceNumber: string;
    memo: string | null;
    systemPostedOn: string | null;
    userPostedOn: string | null;
    voidedOn: string | null;
    clearedOn: string | null;
    transactionDate: string | null;
  };
  
  type TransactionId = string | null;
  
  const ClosingChecklistLedgers = () => {
    const [searchParams] = useSearchParams();
    const orderNumber = searchParams.get("order");
    const orderInfo = useOrderInfo();
  
    const [buyerRecordingFees, setBuyerRecordingFees] = useState("");
    const [sellerRecordingFees, setSellerRecordingFees] = useState("");
    const [buyerTransferTax, setBuyerTransferTax] = useState("");
    const [sellerTransferTax, setSellerTransferTax] = useState("");
    const [mortgageTax, setMortgageTax] = useState("");
    const [withholdingTax, setWithholdingTax] = useState("");
    const [recordingModal, setRecordingModal] = useState(false);
    const [transactions, setTransactions] = useState<LedgerTransaction[]>([]);
  
    const [buyerRecordingFeeTransaction, setBuyerRecordingFeeTransaction] = useState<TransactionId>(null);
    const [sellerRecordingFeeTransaction, setSellerRecordingFeeTransaction] = useState<TransactionId>(null);
    const [buyerTransferTaxTransaction, setBuyerTransferTaxTransaction] = useState<TransactionId>(null);
    const [sellerTransferTaxTransaction, setSellerTransferTaxTransaction] = useState<TransactionId>(null);
    const [mortgageTaxTransaction, setMortgageTaxTransaction] = useState<TransactionId>(null);
    const [withholdingTaxTransaction, setWithholdingTaxTransaction] = useState<TransactionId>(null);
  
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [recordingLedgerInfo, setRecordingLedgerInfo] = useState<RecordingLedgerInfo | null>(null);
  
    const [isRecordingTransferInProgress, setIsRecordingTransferInProgress] = useState(false);
  
    const loadExistingRecordingLedger = async () => {
      const { data } = await axios.get(`/proxy/api/fundstransfer/GetRecordingTransactions?fileNumber=${orderNumber}`);
      setIsLoading(false);
      setRecordingLedgerInfo(data);
    };
  
    const loadTransactions = async () => {
        const response = await axios.get<LedgerTransaction[]>(
            `/proxy/api/ledgers/gettransactions?orderNumber=${orderNumber}`
        );
      const filteredTransactions = response.data.filter(
          (transaction) => {
            return transaction.typeDescription.startsWith("Ledger Transfer") &&
            transaction.status === "Pending"
          }
      );
      setTransactions(filteredTransactions);
      console.log(filteredTransactions)
    };
  
    useEffect(() => {
      loadExistingRecordingLedger();
      loadTransactions();
    }, []);
  
    const getRecordingLedgerUI = () => {
      const { recordingFees, transferTax, mortgageTax, withholdingTax } = recordingLedgerInfo
        ? recordingLedgerInfo
        : ({} as RecordingLedgerInfo);
      const total = [recordingFees, transferTax, mortgageTax]
        .filter((f) => Boolean(f))
        .reduce((prev, curr) => prev! + curr!, 0);
  
      const transactions = [
        { name: "Recording Fees", amount: recordingFees },
        { name: "Transfer Tax", amount: transferTax },
        { name: "Mortgage Tax", amount: mortgageTax },
        { name: "Withholding Tax", amount: withholdingTax }
      ].filter((t) => t.amount) as LedgerTransaction[];
  
      return (
        <>
          <Button
            variant="outlined"
            onClick={() => setRecordingModal(true)}
            size="small"
            disabled={!!recordingLedgerInfo || isLoading}
          >
            Transfer Recording Fees
          </Button>
          {!isLoading && recordingLedgerInfo && (
            <LedgerSummary ledgerTypeHeader={`${orderNumber}-Recording`} total={total!} transactions={transactions} />
          )}
          {isLoading && <LedgerLoader />}
        </>
      );
    };
  
    const onRecordingModalTransferClick = async () => {
      setIsRecordingTransferInProgress(true);
      await axios.post("/proxy/api/fundstransfer/TransferToRecordingLedger", {
      
        fileNumber: orderNumber,
        buyerRecordingFees,
        sellerRecordingFees,
        buyerTransferTax,
        sellerTransferTax,
        mortgageTax,
        withholdingTax,
        buyerRecordingFeeTransactionId: buyerRecordingFeeTransaction,
        sellerRecordingFeeTransactionId: sellerRecordingFeeTransaction,
        buyerTransferTaxTransactionId: buyerTransferTaxTransaction,
        sellerTransferTaxTransactionId: sellerTransferTaxTransaction,
        mortgageTaxTransactionId: mortgageTaxTransaction,
        withholdingTaxTransactionId: withholdingTaxTransaction
      });
      
      await loadExistingRecordingLedger();
      setIsRecordingTransferInProgress(true);
      setRecordingModal(false);
    };
  
    const onRecordingModalCancelClick = () => {
      setRecordingModal(false);
    };
  
    const shouldShowWithholdingTax = orderInfo?.properties?.some((p) => ["NJ", "MD", "DE"].some((s) => p.state === s));
  
    const selectedTransactions = [buyerRecordingFeeTransaction, sellerRecordingFeeTransaction, withholdingTaxTransaction, mortgageTaxTransaction, buyerTransferTaxTransaction, sellerTransferTaxTransaction]
    const unselectedTransactions = transactions.filter(t => !selectedTransactions.includes(t.id));
    return (
      <>
        <Stack direction="column" spacing={1} maxWidth={400}>
          {getRecordingLedgerUI()}
        </Stack>
        <Dialog open={recordingModal} maxWidth="sm" fullWidth>
          <DialogTitle>Transfer Funds to Recording</DialogTitle>
          <DialogContent sx={{ paddingBottom: 4 }}>
            <Grid container spacing={2} sx={{ marginTop: 1 }}>
              <Grid item xs={12} container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                      disabled={Boolean(buyerRecordingFeeTransaction)}
                      fullWidth
                      label="Buyer Recording Fees"
                      type="number"
                      value={buyerRecordingFees}
                      onChange={(e) => setBuyerRecordingFees(e.target.value)}
                      InputProps={{
                        startAdornment: "$"
                      }}
                      InputLabelProps={{ shrink: true }}
                      variant="outlined"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                      select
                      fullWidth
                      label="Apply To"
                      value={buyerRecordingFeeTransaction}
                      onChange={(e) => {
                        const transaction = transactions.find(t => t.id === e.target.value);
                        if(transaction) {
                          setBuyerRecordingFees(transaction?.amount.toString());
                          setBuyerRecordingFeeTransaction(transaction.id)
                        }
                      }}
                      variant="outlined"
                      InputProps={{
                        endAdornment: buyerRecordingFeeTransaction && (
                            <InputAdornment position="end">
                              <IconButton
                                  onClick={() => {
                                    setBuyerRecordingFeeTransaction(null);
                                    setBuyerRecordingFees('');
                                  }}
                                  size="small"
                              >
                                <Iconify icon="material-symbols:close-small" />
                              </IconButton>
                            </InputAdornment>
                        ),
                      }}
                  >
                    {[...unselectedTransactions,transactions.find(t => t.id === buyerRecordingFeeTransaction)].filter(a => Boolean(a)).map((transaction) => (
                        <MenuItem key={transaction!.id} value={transaction!.id}>
                          {transaction!.name} - ${transaction!.amount.toFixed(2)}
                        </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </Grid>
  
              <Grid item xs={12} container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                      disabled={Boolean(sellerRecordingFeeTransaction)}
                      fullWidth
                      label="Seller Recording Fees"
                      type="number"
                      value={sellerRecordingFees}
                      onChange={(e) => setSellerRecordingFees(e.target.value)}
                      InputProps={{ 
                        startAdornment: "$"
                      }}
                      InputLabelProps={{ shrink: true }}
                      variant="outlined"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                      select
                      fullWidth
                      label="Apply to"
                      value={sellerRecordingFeeTransaction}
                      onChange={(e) => {
                        const transaction = transactions.find(t => t.id === e.target.value);
                        setSellerRecordingFees((transaction?.amount) as any)
                        setSellerRecordingFeeTransaction(e.target.value)}}
                      variant="outlined"
                      InputProps={{
                        endAdornment: sellerRecordingFeeTransaction && (
                            <InputAdornment position="end">
                              <IconButton
                                  onClick={() => {
                                    setSellerRecordingFeeTransaction(null);
                                    setSellerRecordingFees('');
                                  }}
                                  size="small"
                              >
                                <Iconify icon="material-symbols:close-small" />
                              </IconButton>
                            </InputAdornment>
                        ),
                      }}
                  >
                    
                    {[...unselectedTransactions, transactions.find(t => t.id === sellerRecordingFeeTransaction)].filter(a => !!a).map((transaction) => (
                        <MenuItem key={transaction!.id} value={transaction!.id}>
                          {transaction!.name} - ${transaction!.amount.toFixed(2)}
                        </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </Grid>
  
              <Grid item xs={12} container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                      disabled={Boolean(buyerTransferTaxTransaction)}
                      fullWidth
                      label="Buyer Transfer Tax"
                      type="number"
                      value={buyerTransferTax}
                      onChange={(e) => setBuyerTransferTax(e.target.value)}
                      InputProps={{
                        startAdornment: "$"
                      }}
                      InputLabelProps={{ shrink: true }}
                      variant="outlined"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                      select
                      fullWidth
                      label="Apply to"
                      value={buyerTransferTaxTransaction}
                      onChange={(e) => {
                        const transaction = transactions.find(t => t.id === e.target.value);
                        setBuyerTransferTax((transaction?.amount) as any)
                        setBuyerTransferTaxTransaction(e.target.value)}
                      }
                      variant="outlined"
                      InputProps={{
                        endAdornment: buyerTransferTaxTransaction && (
                            <InputAdornment position="end">
                              <IconButton
                                  onClick={() => {
                                    setBuyerTransferTaxTransaction(null);
                                    setBuyerTransferTax('')
                                  }}
                                  size="small"
                              >
                                <Iconify icon="material-symbols:close-small" />
                              </IconButton>
                            </InputAdornment>
                        ),
                      }}
                  >
                    {[...unselectedTransactions, transactions.find(t => t.id === buyerTransferTaxTransaction)].filter(a => !!a).map((transaction) => (
                        <MenuItem key={transaction!.id} value={transaction!.id}>
                          {transaction!.name} - ${transaction!.amount.toFixed(2)}
                        </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </Grid>
  
              <Grid item xs={12} container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                      disabled={Boolean(sellerTransferTaxTransaction)}
                      fullWidth
                      label="Seller Transfer Tax"
                      type="number"
                      value={sellerTransferTax}
                      onChange={(e) => 
                          setSellerTransferTax(e.target.value)}
                      InputProps={{
                        startAdornment: "$"
                      }}
                      InputLabelProps={{ shrink: true }}
                      variant="outlined"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                      select
                      fullWidth
                      label="Apply to"
                      value={sellerTransferTaxTransaction}
                      onChange={(e) =>{
                        const transaction = transactions.find(t => t.id === e.target.value);
                        setSellerTransferTax((transaction?.amount) as any);
                        setSellerTransferTaxTransaction(e.target.value);   
                      }}
                      variant="outlined"

                      InputProps={{
                        endAdornment: sellerTransferTaxTransaction && (
                            <InputAdornment position="end">
                              <IconButton
                                  onClick={() => {
                                    setSellerTransferTaxTransaction(null);
                                    setSellerTransferTax('');
                                  }}
                                  size="small"
                              >
                                <Iconify icon="material-symbols:close-small" />
                              </IconButton>
                            </InputAdornment>
                        ),
                      }}
                  >
                      {[...unselectedTransactions, transactions.find(t => t.id === sellerTransferTaxTransaction)].filter(a => !!a).map((transaction) => (
                          <MenuItem key={transaction!.id} value={transaction!.id}>
                            {transaction!.name} - ${transaction!.amount.toFixed(2)}
                          </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </Grid>
  
              <Grid item xs={12} container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                      disabled={Boolean(mortgageTaxTransaction)}
                      fullWidth
                      label="Mortgage Tax"
                      type="number"
                      value={mortgageTax}
                      onChange={(e) => setMortgageTax(e.target.value)}
                      InputProps={{
                        startAdornment: "$"
                      }}
                      InputLabelProps={{ shrink: true }}
                      variant="outlined"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                      
                      select
                      fullWidth
                      label="Apply to"
                      value={mortgageTaxTransaction}
                      onChange={(e) =>{
                        const transaction = transactions.find(t => t.id === e.target.value);
                        setMortgageTax((transaction?.amount) as any);
                        setMortgageTaxTransaction(e.target.value);
                        setMortgageTaxTransaction(e.target.value)
                      }}
                      variant="outlined"
                      InputProps={{
                        endAdornment: mortgageTaxTransaction && (
                            <InputAdornment position="end">
                              <IconButton
                                  onClick={() => {
                                    setMortgageTaxTransaction(null);
                                    setMortgageTax('');
                                  }}
                                  size="small"
                              >
                                <Iconify icon="material-symbols:close-small" />
                              </IconButton>
                            </InputAdornment>
                        ),
                      }}
                  >
                    {[...unselectedTransactions, transactions.find(t => t.id === mortgageTaxTransaction)].filter(a => !!a).map((transaction) => (
                        <MenuItem key={transaction!.id} value={transaction!.id}>
                          {transaction!.name} - ${transaction!.amount.toFixed(2)}
                        </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </Grid>
  
              {shouldShowWithholdingTax && (
                  <Grid item xs={12} container spacing={2}>
                    <Grid item xs={6}>
                      <TextField
                          disabled={Boolean(withholdingTaxTransaction)}
                          fullWidth
                          label="Withholding Tax"
                          type="number"
                          value={withholdingTax}
                          onChange={(e) => setWithholdingTax(e.target.value)}
                          InputProps={{
                            startAdornment: "$"
                          }}
                          InputLabelProps={{ shrink: true }}
                          variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                          select
                          fullWidth
                          label="Apply to"
                          value={withholdingTaxTransaction}
                          onChange={(e) => {
                            const transaction = transactions.find(t => t.id === e.target.value);
                            setWithholdingTax((transaction?.amount) as any);
                            setWithholdingTaxTransaction(e.target.value)
                          }}
                          variant="outlined"
                          InputProps={{
                            endAdornment: withholdingTaxTransaction && (
                                <InputAdornment position="end">
                                  <IconButton
                                      onClick={() => {
                                        setWithholdingTaxTransaction(null);
                                        setWithholdingTax('');
                                      }}
                                      size="small"
                                  >
                                    <Iconify icon="material-symbols:close-small" />
                                  </IconButton>
                                </InputAdornment>
                            ),
                          }}
                      >
                        {[...unselectedTransactions, transactions.find(t => t.id === withholdingTaxTransaction)].filter(a => !!a).map((transaction) => (
                            <MenuItem key={transaction!.id} value={transaction!.id}>
                              {transaction!.name} - ${transaction!.amount.toFixed(2)}
                            </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                  </Grid>
              )}
            </Grid>
  
            <Grid container justifyContent="flex-end" spacing={2} sx={{ mt: 3, paddingRight: 2 }}>
              <Grid item>
                <Button onClick={onRecordingModalCancelClick} color="error" variant="contained">
                  Cancel
                </Button>
              </Grid>
              <Grid item>
                <Button onClick={onRecordingModalTransferClick} color="primary" variant="contained">
                  {isRecordingTransferInProgress ? <CircularProgress size={24} color="inherit" /> : "Transfer"}
                </Button>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
  
  
  
      </>
    );
  };
  
  export default ClosingChecklistLedgers;
