import { countAndMapAssignedTasks } from "./commonUtils";
import { MemberTaskCount } from "../../types";
import axios from "axios";
import { EscrowOverdueOrder } from "../../escrowOverdueOrders-old/types";

export const searchEscrowOverdueOrders = async (): Promise<EscrowOverdueOrder[]> => {
  const { data } = await axios.get<EscrowOverdueOrder[]>(`/api/outstandingchecks/getEscrowOverdueOrders`);
  return data;
};

export const getEscrowOverdueOrdersUniqueAssignedToList = (
  escrowOverdueOrders: EscrowOverdueOrder[]
): MemberTaskCount[] => countAndMapAssignedTasks(escrowOverdueOrders, "Escrow Overdue Orders");
