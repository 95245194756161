import { Box, Button, Container, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import axios from "axios";
import { MRT_TableInstance, MaterialReactTable } from "material-react-table";
import { useContext, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { StringParam, useQueryParam } from "use-query-params";
import Iconify from "../../../minimals/components/iconify";
import NotesModal from "../../checks/checkNotes/NotesModal";
import { DashboardReportType } from "../../checks/types";
import { UserContext } from "../../contexts/UserContext";
import { InternalUser } from "../../types/app";
import { NotesColumn } from "../NotesColumn";
import Actions from "../escrowLedgers/Actions";
import { columns } from "./columns";
import { NonZeroLedgerBalanceInfo } from "./types";
import { exportTableToCsv } from "../../taskUtils";

export default function NonZeroLedgerBalance() {
  const [nonZeroLedgers, setNonZeroLedgers] = useState<NonZeroLedgerBalanceInfo[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [assignedToMe, setAssignedToMe] = useState<boolean>(true);
  const [assignedToId] = useQueryParam("assignedtoid", StringParam);
  const [assignedToUser, setAssignedToUser] = useState<InternalUser | null>(null);
  const [selectedOrder, setSelectedOrder] = useState<NonZeroLedgerBalanceInfo | null>(null);
  const [enableOrdering, setEnableOrdering] = useState(false);

  const { user } = useContext(UserContext);
  const tableRef = useRef<MRT_TableInstance<NonZeroLedgerBalanceInfo> | null>(null);
  const isUnassignedOnly = Number(assignedToId) == -1;

  const getAndSetAssignedToUser = async () => {
    if (!assignedToId || isUnassignedOnly) {
      return;
    }
    const { data } = await axios.get<InternalUser>(
      `/api/clientphonebook/internalusers/getInternalUserById?id=${assignedToId}`
    );
    setAssignedToUser(data);
  };

  const filteredNonZeroLedgers = assignedToMe
    ? nonZeroLedgers.filter((o) => {
        if (isUnassignedOnly) {
          return !o.assignedToUser;
        }
        if (assignedToId) {
          return assignedToId == String(o.assignedToUser?.id || "");
        }
        return user?.id && user.id == o.assignedToUser?.id;
      })
    : nonZeroLedgers;

  const fetchNonZeroLedgers = async (useLoader: boolean) => {
    if (useLoader) {
      setLoading(true);
    }
    const { data } = await axios.get<NonZeroLedgerBalanceInfo[]>("/api/outstandingchecks/GetNonZeroLedgerBalance");
    setNonZeroLedgers(data);
    if (useLoader) {
      setLoading(false);
    }
  };

  const toggleColumnOrdering = () => {
    setEnableOrdering(!enableOrdering);
  };

  useEffect(() => {
    fetchNonZeroLedgers(true);
    getAndSetAssignedToUser();
  }, []);

  const onRowsDeselected = () => {
    if (tableRef.current) {
      tableRef.current.toggleAllRowsSelected(false);
    }
  };

  const columnsWithNotes = NotesColumn<NonZeroLedgerBalanceInfo>(columns, setSelectedOrder);

  return (
    <>
      <Helmet>
        <title>Non Zero Ledger Balances | TitleQ</title>
      </Helmet>

      <Container maxWidth="xl">
        <Typography variant="h4" marginBottom={2}>
          Non Zero Ledger Balances
          {isUnassignedOnly && " - Unassigned"}
          {assignedToUser && ` - Assigned to ${assignedToUser.firstName} ${assignedToUser.lastName}`}
        </Typography>
        <MaterialReactTable
          columns={columnsWithNotes}
          data={filteredNonZeroLedgers}
          enableGlobalFilter
          enableFacetedValues
          enableRowSelection
          enableColumnOrdering={enableOrdering}
          initialState={{
            density: "compact",
            pagination: { pageIndex: 0, pageSize: 30 },
            columnVisibility: {
              department: false
            }
          }}
          state={{
            showSkeletons: loading
          }}
          muiTableBodyProps={{
            sx: {
              "& .MuiTableRow-root:hover .MuiTableCell-root": {
                backgroundColor: "inherit !important"
              }
            }
          }}
          muiTableBodyRowProps={({ row }) => {
            return {
              style: row.index % 2 === 0 ? { backgroundColor: "#f4f4f4" } : {}
            };
          }}
          renderTopToolbarCustomActions={({ table }) => {
            return (
              <>
                <Box sx={{ flexGrow: 1, position: "relative" }}>
                  <Stack direction="row" spacing={2} alignItems="center">
                    <Actions
                      assignedToMe={assignedToMe}
                      setAssignedToMe={setAssignedToMe}
                      count={filteredNonZeroLedgers.length}
                      hideAssignedToMeOptions={!!assignedToId}
                      table={table}
                      fetchTableData={() => fetchNonZeroLedgers(true)}
                      reportType={DashboardReportType.NonZeroLedgerBalances}
                      onRowsDeselected={onRowsDeselected}
                    />
                    <Box sx={{ position: "absolute", right: 0 }}>
                      <Tooltip title="Column ordering">
                        <IconButton onClick={toggleColumnOrdering}>
                          <Iconify icon="mdi:reorder-vertical" />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Export to csv">
                        <IconButton onClick={() => exportTableToCsv(table, "export.csv")}>
                          <Iconify icon="eva:download-fill" />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </Stack>
                </Box>
              </>
            );
          }}
        />
      </Container>
      {!!selectedOrder && (
        <NotesModal
          isOpen
          nameBeforeUpdate={selectedOrder?.assignedToUser?.fullName || ""}
          emailBeforeUpdate={selectedOrder?.assignedToUser?.email || ""}
          entity={selectedOrder}
          setEntity={setSelectedOrder}
          setEntityTable={setNonZeroLedgers}
          refetchData={() => fetchNonZeroLedgers(false)}
          reportType={DashboardReportType.NonZeroLedgerBalances}
        />
      )}
    </>
  );
}
