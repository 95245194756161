import { useState, useMemo } from "react";
import { BasicTask, EventType, StatusCategory } from "../types";
import { Contact } from "../../shared/orderInfo/types";
import useStatusCategories from "./useStatusCategories";

export const useTaskFilters = (
  tasks: BasicTask[],
  contacts: Contact[],
  eventTypes: EventType[],
) => {
  const [statusFilters, setStatusFilters] = useState<StatusCategory[]>([]);
  const [activityFilters, setActivityFilters] = useState<EventType[]>([]);
  const [assignedToFilters, setAssignedToFilters] = useState<string[]>([]);
  const [associationFilters, setAssociationFilters] = useState<Contact[]>([]);
  const [taskFilter, setTaskFilter] = useState("");
  const [taskQuickNoteFilter, setTaskQuickNoteFilter] = useState("");
  const [hideCompleted, setHideCompleted] = useState<boolean>(true);

  const [filtersVisible, setFiltersVisible] = useState(false);
  const { statusCategories } = useStatusCategories();

  const associationFilterOptions = useMemo(() => {
    const taskContactCodes = tasks.map((t) => t.contactCode);
    return contacts.filter((c) => taskContactCodes.includes(c.code));
  }, [tasks, contacts]);

  const assignedToFilterOptions = useMemo(() => {
    if (!tasks.length) return [];
    return [...new Set(tasks.map((t) => t.assignedToName).filter(Boolean))];
  }, [tasks]);

  const activityFilterOptions = useMemo(() => {
    if (!tasks.length) return [];
    return eventTypes.filter((e) =>
      tasks.some((t) => {
        const latestTask = t.statusUpdateHistory[t.statusUpdateHistory.length - 1];
        return latestTask && e.id === latestTask.eventType;
      })
    );
  }, [tasks, eventTypes]);

  const filterTasks = (tasks: BasicTask[]) => {
    const filterRecursive = (task: BasicTask): BasicTask | null => {
      const statusCategory =  statusCategories.find((s) => s.id === task.taskItemStatus.taskStatusType);
      
      const isPassTaskFilter =
        ((hideCompleted && statusCategory && !["Completed", "N/A"].includes(statusCategory.description)) || !hideCompleted) &&
        (!taskFilter || task.description.toLowerCase().includes(taskFilter.toLowerCase()));

      const isPassTaskQuickNote =
        !taskQuickNoteFilter || task.quickNote?.toLowerCase().includes(taskQuickNoteFilter.toLowerCase());

      const filterStatusIds = statusFilters.map((s) => s.id);
      const isPassStatusFilter = !filterStatusIds.length || filterStatusIds.includes(task.taskItemStatus.taskStatusType);

      const filterContactCodes = associationFilters.map((a) => a.code);
      const isPassAssociationFilters = !filterContactCodes.length || filterContactCodes.includes(task.contactCode);

      const activityFiltersIds = activityFilters.map((a) => a.id);
      const latestTaskEventId = task.statusUpdateHistory[task.statusUpdateHistory.length - 1]?.eventType;
      const isPassActivityFilter = !activityFiltersIds.length || activityFiltersIds.includes(latestTaskEventId);

      const isPassAssignedToFilter = !assignedToFilters.length || assignedToFilters.includes(task.assignedToName);

      const isParentTask = task.subTasks.length > 0;

      const isTaskPassing =
        !isParentTask &&
        isPassStatusFilter &&
        isPassTaskFilter &&
        isPassAssociationFilters &&
        isPassActivityFilter &&
        isPassAssignedToFilter &&
        isPassTaskQuickNote;

      if (!isTaskPassing) {
        // Filter the subtasks if the main task does not pass
        const filteredSubTasks = task.subTasks.map(filterRecursive).filter((t) => t !== null) as BasicTask[];
        if (filteredSubTasks.length > 0) {
          // If any subtasks pass, return a copy of the task with filtered subtasks
          return { ...task, subTasks: filteredSubTasks };
        }
        return null;
      }

      // If the task itself passes, apply the filter to subtasks as well
      const filteredSubTasks = task.subTasks.map(filterRecursive).filter((t) => t !== null) as BasicTask[];
      return { ...task, subTasks: filteredSubTasks };
    };

    return tasks.map(filterRecursive).filter((t) => t !== null && t.subTasks.length === 0) as BasicTask[]; //remove parent and empty tasks
  };

  let filteredSortedTasks = filterTasks(tasks);

  return {
    statusFilters,
    setStatusFilters,
    activityFilters,
    setActivityFilters,
    assignedToFilters,
    setAssignedToFilters,
    associationFilters,
    setAssociationFilters,
    taskFilter,
    setTaskFilter,
    taskQuickNoteFilter,
    setTaskQuickNoteFilter,
    associationFilterOptions,
    assignedToFilterOptions,
    activityFilterOptions,
    filtersVisible,
    setFiltersVisible,
    filteredSortedTasks,
    hideCompleted,
    setHideCompleted
  };
};
