import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  TableContainer,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Box,
  Stack
} from "@mui/material";
import { BasicTask, EventType, StatusCategory } from "./types";
import { fMonthDayYearDotNotation, fTime } from "../utils/formatTime";
import { statusColorMap } from "./statusColorMap";

export default function TaskHistory({
  task,
  eventTypes,
  onClose
}: {
  task: BasicTask;
  eventTypes: EventType[];
  onClose: () => void;
}) {
  return (
    <Dialog open={true} fullWidth maxWidth="lg" onClose={onClose}>
      <DialogTitle>
        <Stack direction="row" justifyContent="space-between">
          <Box>
            <Typography variant="subtitle1">{task.description}</Typography>
            <Typography variant="subtitle1" color="#637381">
              Task History
            </Typography>
          </Box>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <TableContainer component={Card}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Date/Time</TableCell>
                <TableCell>Event</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>User</TableCell>
                <TableCell>Notes</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {task.statusUpdateHistory.map((h) => (
                <TableRow sx={{ verticalAlign: "top" }}>
                  <TableCell>
                    <Typography variant="subtitle2">{fMonthDayYearDotNotation(h.dateTime)}</Typography>
                    <Typography variant="subtitle2" color="#637381" fontWeight={400}>
                      {fTime(h.dateTime)}
                    </Typography>
                  </TableCell>
                  <TableCell>{eventTypes.find((e) => e.id === h.eventType)?.description}</TableCell>
                  <TableCell sx={{ wordWrap: "normal" }}>
                    <Typography
                      variant="subtitle2"
                      color={statusColorMap[h.taskItemStatus?.taskStatusType || ""]}
                      noWrap
                    >
                      {h.taskItemStatus?.description || ""}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="subtitle2">{h.username?.toLowerCase()}</Typography>
                  </TableCell>
                  <TableCell width={300}>{h.note}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {task.statusUpdateHistory.length === 0 && <Typography padding={2}>No history available</Typography>}
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="inherit" onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
