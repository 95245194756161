import React, { useState, useCallback, useEffect } from "react";
import { TextField, Autocomplete, SxProps, AutocompleteRenderOptionState, Theme, Popper } from "@mui/material";
import useDebounce from "../../hooks/useDebounce";

interface DebouncedAutoCompleteProps<TOption> {
  value?: TOption | null;
  options: TOption[];
  getOptionsLabel: (option: TOption) => string;
  renderOption?:
    | ((
        props: React.HTMLAttributes<HTMLLIElement>,
        option: TOption,
        state: AutocompleteRenderOptionState
      ) => React.ReactNode)
    | undefined;
  onSelectedOptionChanged: (
    e: React.SyntheticEvent<Element, Event>,
    option: TOption | null | string,
    reason: string
  ) => void;
  textboxLabel: string;
  textboxPlaceholder: string;
  textboxMargin?: "none" | "normal" | "dense";
  onDebouncedTextChanged: (text: string) => Promise<void>;
  required?: boolean | undefined;
  error?: boolean | undefined;
  errorMessage?: string;
  disabled?: boolean | undefined;
  freeSolo?: boolean | undefined;
  open?: boolean | undefined;
  onFocus?: () => void | undefined;
  onBlur?: (e?: any | undefined) => void | undefined;
  additionalOnChange?: (e: string) => void | undefined;
  onInputChange?: (r: string) => void | undefined;
  onClick?: () => void | undefined;
  size?: "small" | "medium" | undefined;
  fullWidth?: boolean;
  disableClearable?: boolean;
  sx?: SxProps<Theme> | undefined;
  setText?: any;
  loadingProp?: boolean;
  noOptionsText?: any;
  popperWidth?: any;
  onKeyDown?: (event: any) => void;
  masktype?: "zip";
  InputProps?: any;
  autoHighlight?: boolean;
  isSearch?: boolean;
}

function DebouncedAutoComplete<TOption>(props: DebouncedAutoCompleteProps<TOption>) {
  const {
    renderOption,
    value,
    options,
    getOptionsLabel,
    onSelectedOptionChanged,
    textboxLabel,
    textboxPlaceholder,
    textboxMargin = "none",
    onDebouncedTextChanged,
    required,
    error,
    errorMessage,
    disabled,
    freeSolo,
    open,
    onFocus,
    onBlur,
    additionalOnChange,
    onInputChange,
    onClick,
    fullWidth,
    size,
    disableClearable,
    sx,
    loadingProp,
    noOptionsText,
    popperWidth,
    onKeyDown,
    masktype,
    InputProps,
    autoHighlight = false
  } = props;

  const [textboxText, setTexboxText] = useState("");
  const [loading, setLoading] = useState(false);
  const debouncedText = useDebounce(textboxText, 500);

  const PopperMy = React.useCallback((props: any) => {
    ////This was causing an in issue when poper widths weren't passed in as props. This probably could be done more eloquently
    if (popperWidth) {
      return <Popper {...props} style={{ width: popperWidth }} placement="bottom-start" />;
    }

    return <Popper {...props} placement="bottom-start" />;
  }, []);

  useEffect(() => {
    debouncedText && onDebouncedTextChanged(textboxText);
    setLoading(false);
  }, [debouncedText]);

  return (
    <Autocomplete
      onClick={onClick}
      disableClearable={disableClearable}
      size={size}
      autoHighlight={autoHighlight}
      fullWidth={fullWidth}
      disabled={disabled}
      freeSolo={freeSolo}
      value={value || ""}
      open={open}
      onKeyDown={onKeyDown}
      options={options || []}
      PopperComponent={PopperMy}
      getOptionLabel={(option) => getOptionsLabel(option as TOption)}
      renderOption={renderOption}
      onChange={(e, value, reason) => {
        onSelectedOptionChanged(e, value, reason);
      }}
      filterOptions={(o) => o} /* Don't filter the drop down options based on the text typed into the textbox*/
      onInputChange={(e, v, r) => (onInputChange ? onInputChange(v) : null)}
      renderInput={(params) => (
        <TextField
          margin={textboxMargin}
          required={required}
          error={error}
          value={textboxText}
          {...params}
          InputProps={{ ...params.InputProps, ...InputProps }}
          inputProps={{
            ...params.inputProps,
            name: "password",
            autoComplete: "off",
            type: "text"
          }}
          label={textboxLabel}
          placeholder={textboxPlaceholder}
          onChange={(e) => {
            additionalOnChange ? additionalOnChange(e.target.value) : null;
            setLoading(true);
            setTexboxText(e.target.value);
            props.setText && props.setText(e.target.value);
          }}
          onFocus={onFocus}
          onBlur={onBlur}
        />
      )}
      loading={loadingProp === undefined ? loading : loadingProp}
      sx={sx}
      noOptionsText={noOptionsText}
    />
  );
}

export default DebouncedAutoComplete;
