import styled from "@emotion/styled";
import { Grid, TextField, Typography } from "@mui/material";
import axios from "axios";
import { MuiTelInput } from "mui-tel-input";
import DebouncedAutoComplete from "../../components/DebouncedAutoComplete";
import { Party } from "../types/Party";
import { PropertyInfo } from "../types/Property";

type Props = {
  party: Party;
  setContactInfo: (property: string, value: string | PropertyInfo[], index: number) => void;
  setFullContactInfo: (value: PropertyInfo, index: number) => void;
  index: number;
  sameAsPropertyAddress: boolean;
};

export default function PartyAddress({ party, setContactInfo, setFullContactInfo, index }: Props) {
  const onPropertySearchTextChanged = async (text: string, index: number) => {
    if (text != null && text != "") {
      let { data } = await axios({
        method: "get",
        url: `/api/property/search?searchText=${encodeURIComponent(text)}`
      });
      data = data.suggestions.map(
        (d: any) =>
          ({
            entries: d.entries,
            aptNo: d.secondary,
            address: d.street_line,
            formattedAddress: d.formattedStreet_line,
            city: d.city,
            state: d.state,
            zipCode: d.zipcode
          } as PropertyInfo)
      );
      setContactInfo("searchText", text, index);
      setContactInfo("propertyList", data, index);
    }
  };

  const searchSelectedAddress = async (text: string, selected: string, index: number) => {
    if (text != null && text != "") {
      let { data } = await axios({
        method: "get",
        url: `/api/property/searchSelectedAddress?searchText=${encodeURIComponent(text)}&selected=${selected}`
      });
      data = data.suggestions.map(
        (d: any) =>
          ({
            entries: d.entries,
            aptNo: d.secondary,
            address: d.street_line,
            formattedAddress: d.formattedStreet_line,
            city: d.city,
            state: d.state,
            zipCode: d.zipcode
          } as PropertyInfo)
      );
      setContactInfo("propertyList", data, index);
    }
  };

  return (
    <>
      {!party.sameAsPropertyAddress && (
        <>
          <Grid item xs={12} marginBottom={0}>
            <Typography variant="overline" sx={{ display: "block", color: "text.secondary" }}>
              Address
            </Typography>
          </Grid>
          <Grid item md={6} xs={12} sx={{ position: "relative" }}>
            <DebouncedAutoComplete
              freeSolo
              value={party.address?.propertyInfo}
              options={party.address?.propertyList || []}
              getOptionsLabel={(option) => option?.formattedAddress || ""}
              renderOption={(props, option, { selected }) => (
                <li
                  {...props}
                  key={`${option?.formattedAddress}${option?.entries}${option?.aptNo}${option?.city}${option?.state}${option?.zipCode}`}
                >
                  <div style={{ width: "100%" }}>
                    <div>
                      <Typography sx={{ display: "inline-block" }} variant="subtitle2">
                        {option?.entries && option?.entries > 2
                          ? `${option?.formattedAddress} ${option?.aptNo ? " " + option?.aptNo : ""} (${
                              option?.entries
                            } more entries) ${option?.city}, ${option?.state} ${option?.zipCode}`
                          : `${option?.formattedAddress}${option?.aptNo ? " " + option?.aptNo : ""}, ${option?.city}, ${
                              option?.state
                            } ${option?.zipCode}`}
                      </Typography>
                    </div>
                  </div>
                </li>
              )}
              onSelectedOptionChanged={(e, value) => {
                if (typeof value === "object" && value) {
                  setFullContactInfo(value, index);
                }
              }}
              textboxLabel="Address Quick Entry"
              textboxPlaceholder="Start typing an address..."
              onDebouncedTextChanged={(text) => onPropertySearchTextChanged(text, index)}
            />
          </Grid>
          <Grid item md={6} xs={12} sx={{ position: "relative" }}>
            <TextField
              label="Address 2"
              value={party.address?.propertyInfo?.aptNo || ""}
              onChange={(e) => setFullContactInfo({ ...party.address?.propertyInfo, aptNo: e.target.value }, index)}
              fullWidth
            />
          </Grid>
          <Grid item md={6} xs={12} sx={{ position: "relative" }}>
            <TextField
              label="City"
              value={party.address?.propertyInfo?.city || ""}
              onChange={(e) => setFullContactInfo({ ...party.address?.propertyInfo, city: e.target.value }, index)}
              fullWidth
            />
          </Grid>
          <Grid item md={3} xs={12} sx={{ position: "relative" }}>
            <TextField
              label="State"
              value={party.address?.propertyInfo?.state || ""}
              onChange={(e) => setFullContactInfo({ ...party.address?.propertyInfo, state: e.target.value }, index)}
              fullWidth
            />
          </Grid>
          <Grid item md={3} xs={12} sx={{ position: "relative" }}>
            <TextField
              label="Zip"
              value={party.address?.propertyInfo?.zipCode || ""}
              onChange={(e) => setFullContactInfo({ ...party.address?.propertyInfo, zipCode: e.target.value }, index)}
              fullWidth
            />
          </Grid>
        </>
      )}

      <Grid item md={6} xs={12} sx={{ position: "relative" }}>
        <IconStyle>
          <MuiTelInput
            preferredCountries={["US", "IL"]}
            defaultCountry="US"
            forceCallingCode
            inputProps={{ maxLength: 12 }}
            label="Phone"
            value={party.address?.phone || ""}
            onChange={(e) => setContactInfo("phone", e, index)}
            fullWidth
          />
        </IconStyle>
      </Grid>
      <Grid item md={6} xs={12} sx={{ position: "relative" }}>
        <TextField
          label="Email"
          value={party.address?.email || ""}
          onChange={(e) => setContactInfo("email", e.target.value, index)}
          fullWidth
        />
      </Grid>
    </>
  );
}
const IconStyle = styled.div`
  .MuiTelInput-IconButton {
    img {
      display: none;
    }
  }
`;
