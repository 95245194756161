import axios from "axios";
import { DashboardNote, DashboardReportType } from "../types";
import { InternalUser } from "../../types/app";

export const getNotes = async ({
  selectId,
  orderNumber,
  reportType
}: {
  selectId: number;
  orderNumber: string;
  reportType: DashboardReportType;
}) => {
  if (!selectId || !orderNumber) return;
  let { data } = await axios.get(
    `/api/outstandingchecks/getnotes?selectId=${selectId}&orderNumber=${orderNumber}&reportType=${reportType}`
  );
  return data.sort(
    (a: DashboardNote, b: DashboardNote) =>
      new Date(b.dateCreated || "").getTime() - new Date(a.dateCreated || "").getTime()
  );
};

export const saveNote = async ({
  text,
  selectId,
  orderNumber,
  reportType
}: {
  text: string;
  selectId: number;
  orderNumber: string;
  reportType: DashboardReportType;
}) => {
  if (!selectId || !orderNumber || !text || !reportType) return;
  const payload = { selectId, orderNumber, text, reportType };
  await axios.post("/api/outstandingchecks/addnote", payload);
};

export const saveAssignToInternalUser = async ({
  user,
  selectId,
  orderNumber,
  reportType
}: {
  user: InternalUser | null;
  selectId?: number;
  orderNumber?: string;
  reportType: DashboardReportType;
}) => {
  if (!user || !selectId || !orderNumber) return;
  const payload = [{ selectId, orderNumber, name: user.fullName, email: user.email, reportType }];
  await axios.post("/api/outstandingchecks/assignTo", payload);
};
