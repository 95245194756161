import {
  Button, Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Stack,
  TextField
} from "@mui/material";
import { NumberFormatCustom } from "../components/NumberFormat";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useContext, useState } from "react";
import axios from "axios";
import { UserContext } from "../contexts/UserContext";
import { useOrderInfo } from "../shared/orderInfo/OrderInfoContext";

export default function ClosingChecklistEscrow({
  onClose,
  refresh,
  editableOrderNumber
}: {
  onClose: () => void;
  refresh: () => void;
  editableOrderNumber?: boolean;
}) {
  const { user } = useContext(UserContext);
  const orderInfo = useOrderInfo();
  const [submitting, setSubmitting] = useState(false);
  const [amount, setAmount] = useState<number | null>(null);
  const [memo, setMemo] = useState<string | null>(null);
  const [dueDate, setDueDate] = useState<Date | null>(null);
  const [orderNumber, setOrderNumber] = useState<string | null>(null);
  const [notes, setNotes] = useState<string | null>(null); // State for notes
  const [assignToEscrowTeam, setAssignToEscrowTeam] = useState(true);

  const submitTransfer = async () => {
    setSubmitting(true);
    await axios.post("/proxy/api/ledgers/CreateEscrowLedger", {
      orderNumber: editableOrderNumber ? orderNumber : orderInfo?.number,
      amount,
      for: memo,
      dueDate,
      userName: user?.username,
      notes,
      assignToEscrowTeam
    });
    refresh();
    onClose();
  };

  return (
    <Dialog open={true} maxWidth="sm" fullWidth onClose={onClose}>
      <DialogTitle>Transfer Funds to Escrow</DialogTitle>
      <DialogContent sx={{ paddingBottom: 4 }}>
        <Stack direction="column" marginTop={1} spacing={2}>
          {editableOrderNumber && <TextField label="Order Number" onChange={(e) => setOrderNumber(e.target.value)} />}
          <TextField
            fullWidth
            label="Amount"
            variant="outlined"
            onChange={(e) => setAmount(Number(e.target.value))}
            InputProps={{
              inputComponent: NumberFormatCustom as any
            }}
          />
          <TextField fullWidth label="For" variant="outlined" onChange={(e) => setMemo(e.target.value)} />
          <DatePicker label="Due Date" onChange={setDueDate} />
          <TextField
              fullWidth
              label="Notes"
              variant="outlined"
              multiline
              rows={4}
              onChange={(e) => setNotes(e.target.value)}
          />
          <FormControlLabel
              control={
                <Checkbox
                    checked={assignToEscrowTeam}
                    onChange={(e) => setAssignToEscrowTeam(e.target.checked)}
                />
              }
              label="Assign to Escrow Team"
          />
        </Stack>

        
     
        <Stack direction="row" spacing={1} marginTop={4} justifyContent="flex-end">
          <Button color="error" variant="contained" onClick={onClose}>
            Cancel
          </Button>
          <Button variant="contained" onClick={submitTransfer}>
            {submitting ? (
              <>
                Transferring <CircularProgress size={16} color="inherit" sx={{ marginLeft: 1 }} />
              </>
            ) : (
              "Transfer"
            )}
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
