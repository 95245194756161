import { DateRange } from "@mui/x-date-pickers-pro";
import dayjs from "dayjs";
import { Dispatch, SetStateAction } from "react";
import { InternalUser } from "../types/app";
import { MRT_TableInstance } from "material-react-table";

export enum DashboardReportType {
  OutstandingChecks = 1,
  PoliciesNotIssued = 2,
  EscrowOverdue = 3,
  NonZeroLedgerBalances = 4
}

export interface IAssignable {
  priority?: string;
  selectId: number;
  assignedToUser: InternalUser | null;
  orderNumber: string;
  notes: DashboardNote[];
}

export interface Check {
  amount: number | null;
  clearedOn: string | null;
  id: number;
  ledgerName: string | null;
  memo: string | null;
  extendedMemo: string | null;
  payorPayee: string | null;
  printedBy: string | null;
  referenceNumber: string | null;
  status: string | null;
  transactionDate: string | null;
  transactionType: string | null;
  voidedOn: string | null;
  printedByUserEmail: string | null;
  titleCompanyPrefix: string | null;
  payeeEmail: string | null;
  priority: string;
  notes: DashboardNote[];
  trustAccount: string | null;
  assignedToName: string | null;
  assignedToEmail: string | null;
  department: string;
  funder: string | null;
  funderEmail: string | null;
  postCloser: string | null;
  postCloserUserName: string | null;
  assignedToUser: InternalUser | null;
  printedByUser: InternalUser | null;
  funderUser: InternalUser | null;
  postCloserUser: InternalUser | null;
  selectId: number;
  orderNumber: string;
}

export interface DashboardNote {
  id: number;
  selectId: number;
  orderNumber: string;
  text: string;
  dateCreated: Date;
  createdByUser: string;
}

export interface AssignedTo {
  id: number;
  selectId: number;
  orderNumber: string;
  name: string;
  email: string;
}

export interface ChecksData {
  setDateRange: Dispatch<SetStateAction<DateRange<dayjs.Dayjs>>>;
  loading: boolean;
  checks: Check[];
  dateRange: DateRange<dayjs.Dayjs>;
  setChecks: Dispatch<SetStateAction<Check[]>>;
  assignedToMe: boolean;
  setAssignedToMe: Dispatch<SetStateAction<boolean>>;
  fetchChecks: (params?: fetchChecksParams) => Promise<void>;
  tableRef: React.MutableRefObject<MRT_TableInstance<Check> | null>;
}

interface fetchChecksParams {
  useLoader?: boolean | undefined;
  resetPagination?: boolean | undefined;
}

export interface User {
  id: number;
  name: string;
  email: string;
  username: string;
}

export interface assignedToResponse {
  checkId: number;
  notes: DashboardNote[];
  assignedTo: AssignedTo;
}
