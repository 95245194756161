// @mui
import {
  Autocomplete,
  Box,
  Button,
  FormControlLabel,
  Grid,
  styled,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  Checkbox
} from "@mui/material";
import { Delete } from "@mui/icons-material";
import { Party } from "../types/Party";
import { useEffect, useState, useContext, ReactNode } from "react";
import { OrderContext } from "../../contexts/OrderDataContext";
import PartyAddress from "./PartyAddress";
import { PropertyInfo } from "../types/Property";
import PrefilledIndicator from "../../components/PrefilledIndicator";

// ----------------------------------------------------------------------

const PartyRootStyle = styled("div")(({ theme }) => ({
  alignItems: "center",
  padding: theme.spacing(3, 3.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: theme.palette.grey[200]
}));

const organizationTypeList = [
  "Corporation",
  "Limited Liability Corp.",
  "Limited Liability Company",
  "Limited Partnership",
  "Partnership",
  "Trust",
  "Estate",
  "Other"
];

export default function OrganizationParty(prop: {
  party: Party;
  parties: Party[];
  sameAsPropertyAddress: boolean;
  setType: (type: "Individual" | "Organization", index: number) => void;
  setOrganizationName: (organizationName: string, index: number) => void;
  setOrganizationType: (organizationType: string | null, index: number) => void;
  setOrganizationPayeeName: (organizationPayeeName: string, index: number) => void;
  setOrganizationPayeeSameAsName: (organizationPayeeName: boolean, index: number) => void;
  index: number;
  multiple: boolean;
  removeParty: (index: number) => void;
  isSeller: boolean;
  setContactInfo: (property: string, value: string | PropertyInfo[], index: number) => void;
  setFullContactInfo: (value: PropertyInfo, index: number) => void;
  setAddressSameAsProperty: (sameAsPropertyAddress: boolean, index: number) => void;
  setVesting: (value: string | null, index: number) => void;
}) {
  const {
    party,
    parties,
    setType,
    setOrganizationName,
    setOrganizationType,
    setOrganizationPayeeName,
    setOrganizationPayeeSameAsName,
    index,
    multiple,
    removeParty,
    isSeller,
    setContactInfo,
    setFullContactInfo,
    setAddressSameAsProperty,
    sameAsPropertyAddress,
    setVesting
  } = prop;

  const { transactionType, autoFilled, setAutoFilled, vestingList, searchForPatriotsAndBlackList } =
    useContext(OrderContext);

  const [organizationNameTouched, setOrganizationNameTouched] = useState(false);

  useEffect(() => {
    if (organizationNameTouched) {
      setOrganizationName("", index);
    }
  }, [organizationNameTouched]);

  return (
    <Grid item xs={12} key={index} id={isSeller ? `seller${index}` : `buyer${index}`}>
      <Box component={multiple ? PartyRootStyle : Box}>
        <Grid container spacing={3} alignItems="center">
          {multiple && (
            <Grid item xs={12}>
              <Typography variant="subtitle1">
                {isSeller
                  ? "Seller"
                  : transactionType === "Refinance" || transactionType === "LastOwnerSearch"
                  ? "Borrower"
                  : "Buyer"}{" "}
                #{index + 1}
              </Typography>
            </Grid>
          )}
          <Grid item xs={12}>
            <ToggleButtonGroup size="small" value={party.type} color="primary">
              <ToggleButton value="Individual" onClick={() => setType("Individual", index)}>
                Individual
              </ToggleButton>
              <ToggleButton value="Organization" onClick={() => setType("Organization", index)}>
                Organization
              </ToggleButton>
            </ToggleButtonGroup>
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={party.organizationPayeeSameAsName}
                  onClick={() => setOrganizationPayeeSameAsName(!party.organizationPayeeSameAsName, index)}
                />
              }
              label="Payee same as name"
            />
          </Grid>
          <Grid item md={6} xs={12} position="relative">
            <TextField
              label="Name"
              value={party.organizationName || ""}
              onBlur={async () => {
                if (party.organizationName) {
                  await searchForPatriotsAndBlackList([party.organizationName]);
                }
              }}
              onChange={(e) => {
                setOrganizationName(e.target.value, index);
                if (index === 0) {
                  setAutoFilled(
                    isSeller
                      ? {
                          ...autoFilled,
                          seller: {
                            ...autoFilled.seller,
                            organizationName: {
                              ...autoFilled.seller.organizationName,
                              autoFilled: false
                            }
                          }
                        }
                      : {
                          ...autoFilled,
                          buyer: {
                            ...autoFilled.buyer,
                            organizationName: {
                              ...autoFilled.buyer.organizationName,
                              autoFilled: false
                            }
                          }
                        }
                  );
                }
              }}
              fullWidth
              onFocus={() => {
                if (party.organizationName === "Certify as Found" || party.organizationName === "TBD") {
                  setOrganizationNameTouched(true);
                }
              }}
            />
            {index === 0 && isSeller && autoFilled.seller.organizationName.autoFilled && (
              <PrefilledIndicator title={autoFilled.seller.organizationName.reason} />
            )}
            {index === 0 && !isSeller && autoFilled.buyer.organizationName.autoFilled && (
              <PrefilledIndicator title={autoFilled.buyer.organizationName.reason} />
            )}
          </Grid>
          <Grid item md={6} xs={12} position="relative">
            <Autocomplete
              value={party.organizationType}
              options={organizationTypeList}
              onChange={(e, value) => {
                setOrganizationType(value, index);
                if (index === 0) {
                  setAutoFilled(
                    isSeller
                      ? {
                          ...autoFilled,
                          seller: {
                            ...autoFilled.seller,
                            organizationType: {
                              ...autoFilled.seller.organizationType,
                              autoFilled: false
                            }
                          }
                        }
                      : {
                          ...autoFilled,
                          buyer: {
                            ...autoFilled.buyer,
                            organizationType: {
                              ...autoFilled.buyer.organizationType,
                              autoFilled: false
                            }
                          }
                        }
                  );
                }
              }}
              renderInput={(params) => <TextField {...params} label="Type" placeholder="Start typing..." />}
            />
            {index === 0 && isSeller && autoFilled.seller.organizationType.autoFilled && (
              <PrefilledIndicator title={autoFilled.seller.organizationType.reason} />
            )}
            {index === 0 && !isSeller && autoFilled.buyer.organizationType.autoFilled && (
              <PrefilledIndicator title={autoFilled.buyer.organizationType.reason} />
            )}
          </Grid>
          <Grid item md={6} xs={12}>
            <TextField
              label="Payee Name"
              disabled={party.organizationPayeeSameAsName}
              value={
                party.organizationPayeeSameAsName ? party.organizationName || "" : party.organizationPayeeName || ""
              }
              onChange={(e) => setOrganizationPayeeName(e.target.value, index)}
              onBlur={async () => {
                if (party.organizationPayeeSameAsName || !party.organizationPayeeName) return;
                await searchForPatriotsAndBlackList([party.organizationPayeeName]);
              }}
              fullWidth
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Autocomplete
              freeSolo
              value={party.vesting || ""}
              options={vestingList}
              onChange={(e, value) => {
                setVesting(value, index);
              }}
              renderInput={(params) => <TextField {...params} label="Vested in" placeholder="Start typing..." />}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={party.sameAsPropertyAddress}
                  onClick={() => {
                    setAddressSameAsProperty(!party.sameAsPropertyAddress, index);
                  }}
                />
              }
              label="Address same as property"
            />
          </Grid>
          <PartyAddress
            party={party}
            sameAsPropertyAddress={sameAsPropertyAddress}
            setContactInfo={setContactInfo}
            setFullContactInfo={setFullContactInfo}
            index={index}
          />
          {(index > 0 || (index === 0 && parties.length > 1)) && (
            <Grid item xs={12}>
              <Button color="error" onClick={() => removeParty(index)}>
                <Delete fontSize="small" />
                Remove
              </Button>
            </Grid>
          )}
        </Grid>
      </Box>
    </Grid>
  );
}
