import React, { useEffect, useState } from "react";
import axios from "axios";
import { CircularProgress, IconButton, TextField } from "@mui/material";
import { BlackListResult, NameSearchResult } from "./types";
import ResultList from "./ResultList";
import { Search } from "@mui/icons-material";
import { useLocation } from "react-router-dom";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export default function BlackListNameSearch() {
  const query = useQuery();
  const urlName = query.get("name");
  const [name, setName] = useState("");
  const [score, setScore] = useState("80");
  const [nameError, setNameError] = useState(false);
  const [results, setResults] = useState<BlackListResult[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [noResults, setNoResults] = useState(false);

  useEffect(() => {
    if (urlName) {
      setName(urlName);
      onSubmit(urlName);
    }
  }, [urlName]);
  const onSubmit = async (passedInName: string) => {
    if (!passedInName) {
      setNameError(true);
      return;
    }
    setLoading(true);
    const { data } = await axios.get<NameSearchResult[]>(
      `https://titleblacklistfunction.azurewebsites.net/api/BlackListNameSearch?name=${passedInName}&score=${score}`
    );
    const results = data.reduce((acc, result) => {
      const addScore = result.blackListResults.flatMap((name) => ({ ...name, score: result.score }));
      return acc.concat(addScore);
    }, [] as BlackListResult[]);
    setResults(results);
    setNoResults(results.length === 0);
    setLoading(false);
  };
  return (
    <>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit(name);
        }}
      >
        <div style={{ display: "flex", gap: 8 }}>
          <TextField
            error={nameError}
            label="Search Name"
            value={name}
            onChange={(e) => {
              setName(e.target.value);
              setNameError(false);
              setNoResults(false);
            }}
            helperText={nameError ? <div style={{ color: "red" }}>Please Enter a Name</div> : ""}
          />
          <TextField
            type="number"
            label="Threshold"
            value={score}
            onChange={(e) => {
              setScore(e.target.value);
            }}
          />
          <IconButton type="submit" color="primary">
            {loading ? <CircularProgress size={24} /> : <Search />}
          </IconButton>
        </div>
      </form>
      <div style={{ marginTop: 16 }}>
        {results.length > 0 && <ResultList data={results} />}
        {noResults && <div>No results found for {name}</div>}
      </div>
    </>
  );
}
