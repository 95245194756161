import { Stack } from "@mui/material";
import DashboardBadge from "../reports/dashboard/DashboardBadge";
import { useEffect, useState } from "react";
import { InternalUser } from "../types/app";
import { searchChecks } from "../reports/dashboard/utils/checkUtils";
import { searchPoliciesNotIssued } from "../reports/dashboard/utils/policiesNotIssuedUtils";
import { searchEscrowOverdueOrders } from "../reports/dashboard/utils/escrowOverdueOrdersUtils";
import { searchNonZeroLedgerBalances } from "../reports/dashboard/utils/nonZeroLedgerBalancesUtils";

export default function Dashboard({ internalUsers }: { internalUsers: InternalUser[] }) {
  const [loading, setLoading] = useState<boolean>(true);
  const [checksCount, setChecksCount] = useState<number | null>(null);
  const [policiesCount, setPoliciesCount] = useState<number | null>(null);
  const [escrowOverdueOrdersCount, setEscrowOverdueOrdersCount] = useState<number | null>(null);
  const [nonZeroLedgerBalancesCount, setNonZeroLedgerBalancesCount] = useState<number | null>(null);

  const filterItemsByInternalUsers = (items: any[]) => {
    return items.filter((item) => internalUsers.some((user) => user.id === item.assignedToUser?.id));
  };

  const fetchChecks = async () => {
    const checks = await searchChecks();
    const filteredChecks = filterItemsByInternalUsers(checks);
    setChecksCount(filteredChecks.length);
  };

  const fetchPoliciesNotIssued = async () => {
    const policiesNotIssued = await searchPoliciesNotIssued();
    const filteredPoliciesNotIssued = filterItemsByInternalUsers(policiesNotIssued);
    setPoliciesCount(filteredPoliciesNotIssued.length);
  };

  const fetchEscrowOverdueOrders = async () => {
    const escrowOverdueOrders = await searchEscrowOverdueOrders();
    const filteredEscrowOverdueOrders = filterItemsByInternalUsers(escrowOverdueOrders);
    setEscrowOverdueOrdersCount(filteredEscrowOverdueOrders.length);
  };

  const fetchNonZeroLedgerBalances = async () => {
    const nonZeroLedgerBalances = await searchNonZeroLedgerBalances();
    const filteredNonZeroLedgerBalances = filterItemsByInternalUsers(nonZeroLedgerBalances);
    setNonZeroLedgerBalancesCount(filteredNonZeroLedgerBalances.length);
  };

  useEffect(() => {
    const fetchData = async () => {
      if (internalUsers.length === 0) {
        return;
      }

      setLoading(true);
      try {
        await Promise.all([
          fetchChecks(),
          fetchPoliciesNotIssued(),
          fetchEscrowOverdueOrders(),
          fetchNonZeroLedgerBalances()
        ]);
      } catch (error) {
        console.error("Error fetching dashboard data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [internalUsers]);

  // get first assignedTo for now until we handle multiple assignedToIds
  const assignedToId = internalUsers[0]?.id;

  return (
    <Stack direction="row" spacing={2}>
      <DashboardBadge
        badgeContent={checksCount?.toString()}
        label="Outstanding Checks"
        color="#00a8a8"
        hoverBackgroundColor="#00a8a814"
        hoverColor="#007d7d"
        disabled={!checksCount}
        href={"/checks"}
        loading={loading}
      />
      <DashboardBadge
        badgeContent={policiesCount?.toString()}
        label="Policies Not Issued"
        color="#e74c3c"
        hoverBackgroundColor="#e74c3c14"
        hoverColor="#c0392b"
        disabled={!policiesCount}
        href={`/policies-not-issued`}
        loading={loading}
      />
      <DashboardBadge
        badgeContent={escrowOverdueOrdersCount?.toString()}
        label="Escrow Overdue"
        color="#f39c12"
        hoverBackgroundColor="#f39c1214"
        hoverColor="#d68910"
        disabled={!escrowOverdueOrdersCount}
        href={`/escrow-overdue-orders`}
        loading={loading}
      />
      <DashboardBadge
        badgeContent={nonZeroLedgerBalancesCount?.toString()}
        label="Non Zero Ledger Balances"
        color="#3498db"
        hoverBackgroundColor="#3498db14"
        hoverColor="#2980b9"
        disabled={!nonZeroLedgerBalancesCount}
        href={`/non-zero-ledger-balances`}
        loading={loading}
      />
    </Stack>
  );
}
