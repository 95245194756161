import { EscrowLedger } from "./types";

export function processLedgerData(data: EscrowLedger[]) {
  data.forEach((d) => {
    var orderNumberAndLedgerName = d.name.split("-");
    d.orderNumber = orderNumberAndLedgerName[0];
    d.friendlyName = orderNumberAndLedgerName[orderNumberAndLedgerName.length - 1];
  });
  return data.filter((d) => d.amount);
}
