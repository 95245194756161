import { Alert, Stack, Typography } from "@mui/material";

export default function PatriotSearchResultAlert({ patriotSearchResult }: { patriotSearchResult: string[] }) {
  const getNames = () => {
    if (!patriotSearchResult) {
      return "";
    }
    return patriotSearchResult.map((name, index) => `${index + 1}) ${name}`).join(", ");
  };
  return (
    <Alert severity="error">
      <Stack direction="row" alignItems="center" spacing={2}>
        <Typography variant="body2">
          Patriot search matches found!! {getNames()}.{" "}
          <span style={{ fontWeight: "bold", color: "red" }}>DO NOT CONTINUE </span>
          transaction until confirmed to not be a match.
        </Typography>
      </Stack>
    </Alert>
  );
}
