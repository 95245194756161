import {
  Box,
  Button,
  CircularProgress,
  Stack,
  Typography,
} from '@mui/material';
import axios from 'axios';
import { MaterialReactTable } from 'material-react-table';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import dayjs, { Dayjs } from 'dayjs';
import Iconify from '../../../minimals/components/iconify/Iconify';
import { columns } from './Columns';
import { LedgerType } from './types';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { fCurrency } from '../../utils/formatNumber';
import TransactionHistoryModal from './TransactionHistoryModal';
import LedgerTransferModal from './LedgerTransferModal';
import PendingCheckModal from './PendingCheckModal';

export default function TransactionLedger() {
  const [loading, setLoading] = useState(false);
  const [balanceLoading, setBalanceLoading] = useState(false);
  const [transactionLedgers, setTransactionLedgers] = useState<LedgerType[]>(
    []
  );
  const [ledgerBalance, setLedgerBalance] = useState('');
  const [openHistoryModal, setOpenHistoryModal] = useState(false);
  const [transactionId, setTransactionId] = useState('');
  const [openNewOptions, setOpenNewOptions] = useState(false);
  const [openLedgerTransferModal, setOpenLedgerTransferModal] = useState(false);
  const [openPendingCheckModal, setOpenPendingCheckModal] = useState(false);
  const initialFilterValue = ['Pending', 'Posted', 'Voided'];
  const { orderNumber } = useParams();

  const getTransactions = async () => {
    setLoading(true);
    var url = `/proxy/api/ledgers/gettransactions?orderNumber=${orderNumber}`;
    const { data } = await axios.get(url);
    setTransactionLedgers(data);
    setLoading(false);
  };
  const getLedgerBalance = async () => {
    setBalanceLoading(true);
    const { data } = await axios.get(
      `/proxy/api/ledgers/GetOrderAndLedgerBalance?ordernumber=${orderNumber}`
    );
    setLedgerBalance(data.ledgerBalance);
    setBalanceLoading(false);
  };

  const handleHistoryModal = (id: string) => {
    setOpenHistoryModal(true);
    setTransactionId(id);
  };
  useEffect(() => {
    window.addEventListener('click', () => {
      setOpenNewOptions(false);
    });
    return () => {
      window.removeEventListener('click', () => {
        setOpenNewOptions(false);
      });
    };
  }, []);
  useEffect(() => {
    getTransactions();
    getLedgerBalance();
  }, []);

  return (
    <>
      <Stack textAlign={'center'} margin={4}>
        <Typography variant='h4'>Transaction Ledger</Typography>
      </Stack>
      <Stack
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        marginBottom={4}
        position='relative'
      >
        <Stack direction='column' spacing={2}>
          <Typography variant='h4'>{orderNumber}</Typography>
          <Typography display={'flex'}>
            Ledger Balance{' '}
            <Typography sx={{ color: 'grey' }} variant='subtitle1' mx={1}>
              &bull;
            </Typography>{' '}
            {balanceLoading ? (
              <CircularProgress size={16} />
            ) : (
              fCurrency(ledgerBalance)
            )}
          </Typography>
        </Stack>
        <Button
          variant='contained'
          startIcon={<Iconify icon='eva:plus-fill' />}
          onClick={(e) => {
            e.stopPropagation();
            setOpenNewOptions(!openNewOptions);
          }}
        >
          New
        </Button>
        {openNewOptions && (
          <Stack
            sx={{
              borderRadius: 1,
              padding: 2,
              position: 'absolute',
              top: 68,
              right: 0,
              backgroundColor: '#fff',
              border: '1px solid #919eab',
              zIndex: 500,
            }}
          >
            <Typography
              sx={{
                cursor: 'pointer',
                padding: 1,
                borderRadius: 1,
                ':hover': { backgroundColor: '#f5f5f5' },
              }}
              onClick={() => setOpenLedgerTransferModal(true)}
            >
              Ledger Transfer
            </Typography>
            <Typography
              sx={{
                cursor: 'pointer',
                padding: 1,
                borderRadius: 1,
                ':hover': { backgroundColor: '#f5f5f5' },
              }}
              onClick={() => setOpenPendingCheckModal(true)}
            >
              Pending Check
            </Typography>
          </Stack>
        )}
      </Stack>
      <MaterialReactTable
        columns={columns(handleHistoryModal)}
        data={transactionLedgers}
        initialState={{
          density: 'compact',
          pagination: { pageIndex: 0, pageSize: 30 },
          columnFilters: [{ id: 'status', value: initialFilterValue }],
        }}
        muiTableBodyProps={{
          sx: {
            '& .MuiTableRow-root:hover .MuiTableCell-root': {
              backgroundColor: 'inherit !important',
            },
          },
        }}
        state={{ showSkeletons: loading }}
      />
      {openHistoryModal && (
        <TransactionHistoryModal
          open={openHistoryModal}
          closeModal={() => setOpenHistoryModal(false)}
          transactionId={transactionId}
        />
      )}
      {openLedgerTransferModal && (
        <LedgerTransferModal
          open={openLedgerTransferModal}
          closeModal={() => setOpenLedgerTransferModal(false)}
          orderNumber={orderNumber}
          handleRefreshList={() => getTransactions()}
          handleRefreshBalance={() => getLedgerBalance()}
        />
      )}
      {openPendingCheckModal && (
        <PendingCheckModal
          open={openPendingCheckModal}
          closeModal={() => setOpenPendingCheckModal(false)}
          orderNumber={orderNumber}
          handleRefreshList={() => getTransactions()}
          handleRefreshBalance={() => getLedgerBalance()}
        />
      )}
    </>
  );
}
