import { useState } from "react";
import DebouncedAutoComplete from "../../components/DebouncedAutoComplete";
import { ContactOption } from "./ContactOption";
import axios from "axios";
import { Container, Dialog } from "@mui/material";
import OrderEntryCreateContact from "./OrderEntryCreateContact";
import { Contact, GetDefaultContact } from "../../orderEntry/types/Contact";
import OrderEntryCreateCompany from "./OrderEntryCreateCompany";
import UserNewEditForm from "./new-contact";

export default function PhonebookContactAutocomplete({
  contact,
  setContact,
  apiOptions,
  textboxLabel,
  textboxPlaceholder,
  required,
  error,
  freeSolo,
  onInputChange,
  isCompany,
  disabled
}: {
  contact: Contact | null;
  setContact: (value: Contact | null) => void;
  apiOptions?: object;
  textboxLabel: string;
  textboxPlaceholder?: string;
  required?: boolean;
  error?: boolean;
  freeSolo?: boolean;
  onInputChange?: (value: string | null) => void;
  isCompany?: boolean;
  disabled?: boolean;
}) {
  const [contactList, setContactList] = useState<Contact[]>([]);
  const [createNewContactModal, setCreateNewContactModal] = useState(false);
  const [inputText, setInputText] = useState("");
  const addOption = [{ ...GetDefaultContact(), isButton: true }];

  const getOrderEntryContacts = async (text: string) => {
    const { data } = await axios({
      method: "get",
      url: `/api/clientphonebook/contacts/getOrderEntryContacts`,
      params: {
        searchText: text || "",
        ...apiOptions
      }
    });

    setContactList(addOption.concat(data));
  };

  return (
    <>
      <DebouncedAutoComplete
        fullWidth
        freeSolo={freeSolo}
        popperWidth={650}
        options={contactList}
        value={contact}
        getOptionsLabel={(option) => (option.isIndividual ? option.name : option.company) || ""}
        onSelectedOptionChanged={(e, value) => {
          if (typeof value === "object") {
            setContact(value);
          }
        }}
        textboxLabel={textboxLabel}
        textboxPlaceholder={textboxPlaceholder || ""}
        onDebouncedTextChanged={getOrderEntryContacts}
        setText={setInputText}
        error={error}
        required={required}
        disabled={disabled}
        renderOption={(props, option) => {
          return option.isButton ? (
            <li {...props} key={option.name}>
              {isCompany && (
                <OrderEntryCreateCompany
                  inputText={inputText}
                  onNoOption={(b) => setCreateNewContactModal(true)}
                  onSave={(value: any) => {
                    console.log(value);
                    setContact(value);
                  }}
                />
              )}
              {!isCompany && (
                <OrderEntryCreateContact
                  inputText={inputText}
                  onNoOption={(b) => setCreateNewContactModal(true)}
                  onSave={setContact}
                />
              )}
            </li>
          ) : (
            <ContactOption option={option} props={props} />
          );
        }}
        additionalOnChange={onInputChange}
      />
      <Dialog
        open={createNewContactModal}
        onClose={(event, reason) => {
          if (reason === "escapeKeyDown") {
            setCreateNewContactModal(false);
          }
        }}
        sx={{
          ".MuiDialog-paper": { maxWidth: 1000, width: "100%" },
          ".MuiContainer-root": { maxHeight: "90vh" }
        }}
      >
        <Container sx={{ width: "100%" }}>
          <UserNewEditForm
            afterSaveFunction={(client: any) => {
              if (client) {
                setContact(client);
              }
              setCreateNewContactModal(false);
            }}
            duplicateButtonText="Add to Order"
            submitButtonText="Save & Add to Order"
            onClose={() => setCreateNewContactModal(false)}
            name={inputText}
          />
        </Container>
      </Dialog>
    </>
  );
}
