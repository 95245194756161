import { Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { generateLabel } from "./generateLabel";
import { ContactOrder } from "../types";
import { fMonthDayYearDotNotation } from "../../../../utils/formatTime";

export default function OrdersTable({ orders }: { orders: ContactOrder[] }) {
  return (
    <Table size="medium">
      <TableHead>
        <TableRow>
          <TableCell width="20%">Order #</TableCell>
          <TableCell width="24%">Name</TableCell>
          <TableCell width="24%">Role in Order</TableCell>
          <TableCell width="24%">Represents</TableCell>
          <TableCell width="24%">Closing Date</TableCell>
          <TableCell>Status</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {orders.map((o) => (
          <TableRow key={`${o.contactId}-${o.orderNumber}-${o.closingDate}-${o.orderStatus}-${o.contactType}`}>
            <TableCell>
              <a
                target="_blank"
                style={{ color: "#212B36" }}
                href={`https://internal.mcres.com/new-home/${o.orderNumber}`}
              >
                <Typography variant="subtitle2">
                  {o.titleCompanyPrefix ? `${o.titleCompanyPrefix}-` : ""}
                  {o.orderNumber}
                </Typography>
              </a>
            </TableCell>
            <TableCell>
              <Typography variant="body2">{o.name}</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="body2">{o.contactType}</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="body2">{o.represents}</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="body2">{o.closingDate ? fMonthDayYearDotNotation(o.closingDate) : ""}</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="body2">{generateLabel(o.orderStatus || "")}</Typography>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
