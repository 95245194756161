import { Button, Link, Stack, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import axios from "axios";
import { MRT_ColumnDef } from "material-react-table";
import { formatDate, formatDateWithTime } from "../../../utils";
import { useFlips } from "../../contexts/FlipsDashboardContext";
import { ChecklistTaskStatus, TaskGroupEntityWithStatusInfo, statusColorMap } from "../types";
import { fCurrency } from "../../utils/formatNumber";
import Iconify from "../../../minimals/components/iconify";
import PassFailedActions from "./PassFailedActions";
import SingleDateRangeFilter from "./SingleDateRangeFilter";

export const getColumns = (): MRT_ColumnDef<TaskGroupEntityWithStatusInfo>[] => {
  const { fetchFlips, setIsUpdatingInSelect } = useFlips();

  const updateChecklistTaskToCompleted = async (orderNumber: string) => {
    setIsUpdatingInSelect(true);
    const { data } = await axios.post("/api/taskmanagement/flips/UpdateChecklistTaskToCompleted", { orderNumber });
    fetchFlips(true);
    setIsUpdatingInSelect(false);
  };
  const updateChecklistTaskToRejected = async (orderNumber: string) => {
    setIsUpdatingInSelect(true);
    const { data } = await axios.post("/api/taskmanagement/flips/UpdateChecklistTaskToRejected", { orderNumber });
    fetchFlips(true);
    setIsUpdatingInSelect(false);
  };
  const updateChecklistTaskToRequired = async (orderNumber: string) => {
    setIsUpdatingInSelect(true);
    const { data } = await axios.post("/api/taskmanagement/flips/UpdateChecklistTaskToRequired", { orderNumber });
    fetchFlips(true);
    setIsUpdatingInSelect(false);
  };

  const setPassAudit = async (id: number, passedAudit?: boolean) => {
    await axios.post("/api/taskmanagement/flips/SetPassedAudit", { taskGroupEntityId: id, passedAudit });
    await fetchFlips(true);
  };

  const onPassedAuditClick = async (id: number) => {
    await setPassAudit(id, true);
  };

  const onFailedAuditClick = async (id: number) => {
    await setPassAudit(id, false);
  };

  const onAuditResetToDefaultClick = async (id: number) => {
    await setPassAudit(id, undefined);
  };

  return [
    {
      header: "Order Number",
      size: 50,
      accessorKey: "orderNumber",
      Cell: ({ row, renderedCellValue }) => {
        return (
          <>
            <Link href={`/task-management/flips?order=${row.original.orderNumber}`} target="_blank">
              <Typography variant="body2">{renderedCellValue}</Typography>
            </Link>
          </>
        );
      },
      muiFilterTextFieldProps: {
        variant: "outlined",
        size: "small"
      }
    },
    {
      header: "Marked On",
      size: 50,
      accessorKey: "dateCreated",
      sortingFn: "datetime",
      filterFn: (row, id, filterValue) => {
        if (filterValue && filterValue[0] && filterValue[1] && !row.original.dateCreated) {
          return false;
        } else if (filterValue && filterValue[0] && filterValue[1] && row.original.dateCreated) {
          const dateCreated = new Date(row.original.dateCreated).getTime();
          const startDate = new Date(filterValue[0]).getTime();
          const endDate = new Date(filterValue[1]).getTime();

          return dateCreated >= startDate && dateCreated <= endDate;
        } else {
          return true;
        }
      },
      Filter: SingleDateRangeFilter,
      accessorFn: (row) => (row.dateCreated ? new Date(row.dateCreated) : null),
      Cell: ({ renderedCellValue }) => {
        return <Typography variant="subtitle2">{formatDate(renderedCellValue)}</Typography>;
      },
      muiFilterTextFieldProps: {
        variant: "outlined",
        size: "small"
      }
    },
    {
      header: "Status",
      accessorKey: "checklistTaskStatus",
      Cell: ({ row }) => {
        const status = row.original.checklistTaskStatus;
        const isReviewCompleted = status == ChecklistTaskStatus.ReviewCompleted;
        return (
          <>
            <Typography variant="subtitle2" sx={{ color: statusColorMap[status] }}>
              {row.original.checklistTaskStatus}
            </Typography>
            {isReviewCompleted && (
              <Typography variant="body2" color="GrayText">
                By {row.original.markedAsCompletedBy}
              </Typography>
            )}
          </>
        );
      },
      muiFilterTextFieldProps: {
        variant: "outlined",
        size: "small"
      }
    },
    {
      header: "Checklist Items",
      Cell: ({ row }) => {
        const completedTasks = row.original.tasks?.filter((task) => task.taskItemStatusId !== 1).length || 0;
        return (
          <Typography variant="body2">
            {completedTasks} of {row.original.tasks.length}
          </Typography>
        );
      },
      muiFilterTextFieldProps: {
        variant: "outlined",
        size: "small"
      }
    },
    {
      header: "Spread",
      size: 50,
      Cell: ({ row }) => {
        const { intermediatePrice, endBuyerPrice } = row.original;
        return (
          <Typography variant="body2">
            {intermediatePrice && endBuyerPrice ? fCurrency((endBuyerPrice - intermediatePrice).toString()) : ""}
          </Typography>
        );
      },
      muiFilterTextFieldProps: {
        variant: "outlined",
        size: "small"
      }
    },
    {
      header: "Approved/Rejected By",
      accessorKey: "approvedBy",
      Cell: ({ row }) => {
        return (
          <Typography variant="body2">
            {row.original.approvedBy ? (
              <>
                <Typography variant="subtitle2">{row.original.approvedBy}</Typography>
                <Typography variant="body2" color="GrayText">
                  {formatDateWithTime(row.original.approvedAt)}
                </Typography>
              </>
            ) : (
              ""
            )}
          </Typography>
        );
      },
      muiFilterTextFieldProps: {
        variant: "outlined",
        size: "small"
      }
    },
    {
      header: "Actions",
      Cell: ({ row }) => {
        const selectStatus = row.original.selectStatus;
        if (selectStatus == "Required") {
          return (
            <>
              <Button
                variant="soft"
                size="small"
                color="success"
                onClick={async () => await updateChecklistTaskToCompleted(row.original.orderNumber)}
                startIcon={<Iconify icon="eva:checkmark-fill" />}
              >
                Approve
              </Button>

              <Button
                variant="soft"
                size="small"
                color="error"
                sx={{ ml: 1 }}
                onClick={async () => await updateChecklistTaskToRejected(row.original.orderNumber)}
                startIcon={<Iconify icon="eva:slash-fill" />}
              >
                Reject
              </Button>
            </>
          );
        }

        if (selectStatus == "Completed" || selectStatus == "N/A") {
          return (
            <Button
              variant="contained"
              size="small"
              onClick={async () => await updateChecklistTaskToRequired(row.original.orderNumber)}
              color="inherit"
              startIcon={<Iconify icon="mdi:undo-variant" />}
            >
              {selectStatus == "Completed" ? "Revoke Approval" : "Unreject"}
            </Button>
          );
        }
      },
      muiFilterTextFieldProps: {
        variant: "outlined",
        size: "small"
      }
    },
    {
      header: "Audit Pass/Fail",
      Cell: ({ row }) => {
        const passedAudit = row.original.passedAudit;
        const selectStatus = row.original.selectStatus;
        if (selectStatus !== "Completed") {
          return <></>;
        }
        return (
          <>
            {passedAudit !== null && (
              <Stack direction="row" spacing={2} alignItems="center">
                <Typography variant="subtitle2" sx={{ color: passedAudit ? "success.main" : "error.main" }}>
                  {passedAudit ? "Passed" : "Failed"}
                </Typography>
                <Button
                  variant="contained"
                  size="small"
                  color="inherit"
                  startIcon={<Iconify icon="mdi:undo-variant" />}
                  onClick={async () => onAuditResetToDefaultClick(row.original.id)}
                >
                  Reset
                </Button>
              </Stack>
            )}
            {passedAudit === null && (
              <Stack direction="row" spacing={1}>
                <Button
                  variant="soft"
                  size="small"
                  color="success"
                  startIcon={<Iconify icon="eva:checkmark-fill" />}
                  onClick={async () => onPassedAuditClick(row.original.id)}
                >
                  Passed
                </Button>
                <Button
                  variant="soft"
                  size="small"
                  color="error"
                  startIcon={<Iconify icon="eva:slash-fill" />}
                  onClick={async () => onFailedAuditClick(row.original.id)}
                >
                  Failed
                </Button>
              </Stack>
            )}
          </>
        );
        // if (row.original.selectStatus == "Completed") {
        //   return <PassFailedActions />;
        // }
      },
      muiFilterTextFieldProps: {
        variant: "outlined",
        size: "small"
      }
    },
    {
      header: "Settlement Date",
      size: 50,
      accessorKey: "settlementDate",
      sortingFn: "datetime",
      filterFn: (row, id, filterValue) => {
        if (filterValue && filterValue[0] && filterValue[1] && !row.original.settlementDate) {
          return false;
        } else if (filterValue && filterValue[0] && filterValue[1] && row.original.settlementDate) {
          const settlementDate = new Date(row.original.settlementDate).getTime();
          const startDate = new Date(filterValue[0]).getTime();
          const endDate = new Date(filterValue[1]).getTime();

          return settlementDate >= startDate && settlementDate <= endDate;
        } else {
          return true;
        }
      },
      Filter: SingleDateRangeFilter,
      accessorFn: (row) => (row.settlementDate ? new Date(row.settlementDate) : null),
      Cell: ({ renderedCellValue }) => {
        return <Typography variant="subtitle2">{formatDate(renderedCellValue)}</Typography>;
      },
      muiFilterTextFieldProps: {
        variant: "outlined",
        size: "small"
      }
    }
  ];
};
