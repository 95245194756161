// @mui
import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  Stack,
  styled,
  Switch,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  Checkbox
} from "@mui/material";
import { Party } from "../types/Party";
import { Delete } from "@mui/icons-material";
import { useEffect, useState, useContext } from "react";
import { OrderContext } from "../../contexts/OrderDataContext";
import PartyAddress from "./PartyAddress";
import { PropertyInfo } from "../types/Property";
import PrefilledIndicator from "../../components/PrefilledIndicator";

// ----------------------------------------------------------------------

const PartyRootStyle = styled("div")(({ theme }) => ({
  alignItems: "center",
  padding: theme.spacing(3, 3.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: theme.palette.grey[200]
}));

export default function SingleParty(prop: {
  party: Party;
  parties: Party[];
  sameAsPropertyAddress: boolean;
  setType: (type: "Individual" | "Organization", index: number) => void;
  setIsJoint: (isJoint: boolean, index: number) => void;
  setFirstName1: (firstName1: string, index: number) => void;
  setMiddleName1: (middleName1: string, index: number) => void;
  setLastName1: (lastName1: string, index: number) => void;
  setGender1: (gender1: "Male" | "Female", index: number) => void;
  index: number;
  multiple: boolean;
  removeParty: (index: number) => void;
  isSeller: boolean;
  setContactInfo: (property: string, value: string | PropertyInfo[], index: number) => void;
  setFullContactInfo: (value: PropertyInfo, index: number) => void;
  setAddressSameAsProperty: (sameAsPropertyAddress: boolean, index: number) => void;
}) {
  const {
    party,
    parties,
    setType,
    setIsJoint,
    setFirstName1,
    setMiddleName1,
    setLastName1,
    setGender1,
    index,
    multiple,
    removeParty,
    isSeller,
    setContactInfo,
    setFullContactInfo,
    setAddressSameAsProperty,
    sameAsPropertyAddress
  } = prop;

  const {
    transactionType,
    autoFilled,
    setAutoFilled,
    getFullNameForBlacklistAndPatriotSearching,
    searchForPatriotsAndBlackList
  } = useContext(OrderContext);

  const [firstName1Touched, setFirstName1Touched] = useState(false);

  useEffect(() => {
    if (firstName1Touched) {
      setFirstName1("", index);
    }
  }, [firstName1Touched]);
  return (
    <Grid item xs={12} key={index} id={isSeller ? `seller${index}` : `buyer${index}`}>
      <Box component={multiple ? PartyRootStyle : Box}>
        <Grid container spacing={3} alignItems="center">
          {multiple && (
            <Grid item xs={12}>
              <Typography variant="subtitle1">
                {isSeller
                  ? "Seller"
                  : transactionType === "Refinance" || transactionType === "LastOwnerSearch"
                  ? "Borrower"
                  : "Buyer"}{" "}
                #{index + 1}
              </Typography>
            </Grid>
          )}
          <Grid item md={8} xs={12}>
            <ToggleButtonGroup size="small" value={party.type} color="primary">
              <ToggleButton value="Individual" onClick={() => setType("Individual", index)}>
                Individual
              </ToggleButton>
              <ToggleButton value="Organization" onClick={() => setType("Organization", index)}>
                Organization
              </ToggleButton>
            </ToggleButtonGroup>
          </Grid>
          <Grid item md={4} xs={12}>
            <Stack alignItems="flex-end">
              <FormControlLabel
                control={<Switch checked={party.isJoint} onChange={() => setIsJoint(!party.isJoint, index)} />}
                label="Is Joint"
              />
            </Stack>
          </Grid>
          <Grid item md={6} xs={12} sx={{ position: "relative" }}>
            <TextField
              label="First Name"
              value={party.firstName1 || ""}
              onChange={(e) => setFirstName1(e.target.value, index)}
              fullWidth
              onBlur={async () => {
                const nameToUse = getFullNameForBlacklistAndPatriotSearching({
                  firstName: party.firstName1,
                  middleName: party.middleName1,
                  lastName: party.lastName1
                });
                await searchForPatriotsAndBlackList([nameToUse]);
              }}
              onFocus={() => {
                if (party.firstName1 === "Certify as Found" || party.firstName1 === "TBD") {
                  setFirstName1Touched(true);
                }
                if (index === 0) {
                  setAutoFilled(
                    isSeller
                      ? {
                          ...autoFilled,
                          seller: {
                            ...autoFilled.seller,
                            firstName1: {
                              ...autoFilled.seller.firstName1,
                              autoFilled: false
                            }
                          }
                        }
                      : {
                          ...autoFilled,
                          buyer: {
                            ...autoFilled.buyer,
                            firstName1: {
                              ...autoFilled.buyer.firstName1,
                              autoFilled: false
                            }
                          }
                        }
                  );
                }
              }}
            />
            {index === 0 && isSeller && autoFilled.seller.firstName1.autoFilled && (
              <PrefilledIndicator title={autoFilled.seller.firstName1.reason} />
            )}
            {index === 0 && !isSeller && autoFilled.buyer.firstName1.autoFilled && (
              <PrefilledIndicator title={autoFilled.buyer.firstName1.reason} />
            )}
          </Grid>
          <Grid item md={6} xs={12}>
            <TextField
              label="Middle Name"
              value={party.middleName1 || ""}
              onChange={(e) => setMiddleName1(e.target.value, index)}
              onBlur={async () => {
                const nameToUse = getFullNameForBlacklistAndPatriotSearching({
                  firstName: party.firstName1,
                  middleName: party.middleName1,
                  lastName: party.lastName1
                });
                await searchForPatriotsAndBlackList([nameToUse]);
              }}
              fullWidth
            />
          </Grid>
          <Grid item md={6} xs={12} sx={{ position: "relative" }}>
            <TextField
              label="Last Name"
              value={party.lastName1 || ""}
              onChange={(e) => setLastName1(e.target.value, index)}
              fullWidth
              onBlur={async () => {
                const nameToUse = getFullNameForBlacklistAndPatriotSearching({
                  firstName: party.firstName1,
                  middleName: party.middleName1,
                  lastName: party.lastName1
                });
                await searchForPatriotsAndBlackList([nameToUse]);
              }}
              onFocus={() => {
                if (index === 0) {
                  setAutoFilled(
                    isSeller
                      ? {
                          ...autoFilled,
                          seller: {
                            ...autoFilled.seller,
                            lastName1: {
                              ...autoFilled.seller.lastName1,
                              autoFilled: false
                            }
                          }
                        }
                      : {
                          ...autoFilled,
                          buyer: {
                            ...autoFilled.buyer,
                            lastName1: {
                              ...autoFilled.buyer.lastName1,
                              autoFilled: false
                            }
                          }
                        }
                  );
                }
              }}
            />
            {index === 0 && isSeller && autoFilled.seller.lastName1.autoFilled && (
              <PrefilledIndicator title={autoFilled.seller.lastName1.reason} />
            )}
            {index === 0 && !isSeller && autoFilled.buyer.lastName1.autoFilled && (
              <PrefilledIndicator title={autoFilled.buyer.lastName1.reason} />
            )}
          </Grid>
          <Grid item md={6} xs={12}>
            <ToggleButtonGroup size="small" value={party.gender1} color="primary">
              <ToggleButton value="Male" onClick={() => setGender1("Male", index)}>
                Male
              </ToggleButton>
              <ToggleButton value="Female" onClick={() => setGender1("Female", index)}>
                Female
              </ToggleButton>
            </ToggleButtonGroup>
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={party.sameAsPropertyAddress}
                  onClick={() => {
                    setAddressSameAsProperty(!party.sameAsPropertyAddress, index);
                  }}
                />
              }
              label="Address same as property"
            />
          </Grid>
          <PartyAddress
            party={party}
            sameAsPropertyAddress={sameAsPropertyAddress}
            setContactInfo={setContactInfo}
            setFullContactInfo={setFullContactInfo}
            index={index}
          />
          {(index > 0 || (index === 0 && parties.length > 1)) && (
            <Grid item xs={12}>
              <Button color="error" onClick={() => removeParty(index)}>
                <Delete fontSize="small" />
                Remove
              </Button>
            </Grid>
          )}
        </Grid>
      </Box>
    </Grid>
  );
}
