import { Autocomplete, Box, Card, CardContent, Grid, TextField } from "@mui/material";
import { MuiTelInput, MuiTelInputInfo } from "mui-tel-input";
import isNumber from "../../../utils/isNumber";
import { Company, CompanyType } from "../../types";
import { Updater } from "use-immer";
import useCompanyTypeList from "../../hooks/useCompanyTypeList";

interface IProps {
  company: Company;
  setCompany: Updater<Company>;
}

export default function MainCompanyForm(props: IProps) {
  const { company, setCompany } = props;
  const companyTypeList = useCompanyTypeList();

  const handlePhoneFields = (info: MuiTelInputInfo) => {
    setCompany((draft) => {
      draft.phone = info.nationalNumber || "";
      draft.phoneCountryCode = info.countryCallingCode || "";
    });
  };

  const handleFaxFields = (info: MuiTelInputInfo) => {
    setCompany((draft) => {
      draft.fax = info.nationalNumber || "";
      draft.faxCountryCode = info.countryCallingCode || "";
    });
  };

  const selectedCompanyType =
    companyTypeList.find((t) => t.id === company.companyTypeId) || ({ name: "" } as CompanyType);

  return (
    <>
      <Card>
        <CardContent>
          <Grid container columnSpacing={2} rowSpacing={3}>
            <Grid item xs={6}>
              <TextField
                onChange={(e) =>
                  setCompany((draft) => {
                    draft.name = e.target.value;
                  })
                }
                value={company?.name || ""}
                label="Company Name"
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <Autocomplete
                value={selectedCompanyType}
                getOptionLabel={(option) => option.name}
                options={companyTypeList}
                onChange={(e, option) =>
                  setCompany((draft) => {
                    draft.companyTypeId = option?.id || null;
                  })
                }
                fullWidth
                renderInput={(params) => (
                  <TextField
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password"
                    }}
                    fullWidth
                    label="Company Type"
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <MuiTelInput
                value={`+${isNumber(company.phoneCountryCode) ? company.phoneCountryCode : 1} ${company.phone}`}
                onChange={(value, info) => handlePhoneFields(info)}
                preferredCountries={["US", "IL"]}
                defaultCountry={"US"}
                forceCallingCode
                label="Company Phone"
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <MuiTelInput
                value={`+${isNumber(company.faxCountryCode) ? company.faxCountryCode : 1} ${company.fax}`}
                onChange={(value, info) => handleFaxFields(info)}
                preferredCountries={["US", "IL"]}
                defaultCountry={"US"}
                forceCallingCode
                label="Company Fax"
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                value={company?.email || ""}
                onChange={(e) =>
                  setCompany((draft) => {
                    draft.email = e.target.value;
                  })
                }
                label="Company Email"
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                onChange={(e) =>
                  setCompany((draft) => {
                    draft.website = e.target.value;
                  })
                }
                value={company?.website || ""}
                label="Company Website"
                fullWidth
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Box marginTop={1}>
        <Card>
          <CardContent>
            <Grid item>
              <TextField
                onChange={(e) =>
                  setCompany((draft) => {
                    draft.emailPolicyTo = e.target.value;
                  })
                }
                value={company?.emailPolicyTo || ""}
                label="Email Policy To"
                fullWidth
              />
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </>
  );
}
