import { Container, IconButton, Tooltip, Typography } from "@mui/material";
import { DateRange } from "@mui/x-date-pickers-pro";
import axios from "axios";
import dayjs, { Dayjs } from "dayjs";
import { MaterialReactTable, MRT_ColumnFiltersState, MRT_VisibilityState } from "material-react-table";
import { useEffect, useState } from "react";
import Iconify from "../../../minimals/components/iconify";
import { HistoryWithRedFlags } from "../../phonebook/types";
import { redFlagColumns } from "./RedFlagColumns";
import Actions from "./Actions";

const LOCAL_STORAGE_KEY = "RedFlagsColumnVisibility";
const FILTER_STORAGE_KEY = "RedFlagsFilterState";

export default function RedFlags() {
  const today = dayjs();
  const defaultEndDate = today.endOf("day");
  const defaultStartDate = today.startOf("day");
  const [loading, setLoading] = useState(true);
  const [history, setHistory] = useState<HistoryWithRedFlags[]>([]);
  const [dateRange, setDateRange] = useState<DateRange<Dayjs>>([defaultStartDate, defaultEndDate]);
  const [enableOrdering, setEnableOrdering] = useState(false);
  
  const [columnVisibility, setColumnVisibility] = useState<MRT_VisibilityState>(() => {
    const savedVisibility = localStorage.getItem(LOCAL_STORAGE_KEY);
    return savedVisibility ? JSON.parse(savedVisibility) : {};
  });

  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(() => {
    const savedFilterState = localStorage.getItem(FILTER_STORAGE_KEY);
    return savedFilterState ? JSON.parse(savedFilterState) : [];
  });
  console.log("🚀 ~ const[columnFilters,setColumnFilters]=useState ~ columnFilters:", columnFilters);

  const getAndSetHistory = async () => {
    setLoading(true);
    const [startDate, endDate] = dateRange;

    const fromParam = (startDate?.startOf("day") || defaultStartDate).format("YYYY-MM-DD");
    const toParam = (endDate?.endOf("day") || defaultEndDate).format("YYYY-MM-DD");

    const url = `/api/clientphonebook/redFlags/getContactsAndCompaniesWithRedFlags?from=${fromParam}&to=${toParam}`;
    const { data } = await axios.get(url);
    setHistory(data);
    setLoading(false);
  };

  useEffect(() => {
    getAndSetHistory();
  }, [dateRange]);

  useEffect(() => {
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(columnVisibility));
  }, [columnVisibility]);

  useEffect(() => {
    localStorage.setItem(FILTER_STORAGE_KEY, JSON.stringify(columnFilters));
  }, [columnFilters]);

  return (
    <>
      <Container maxWidth="xl">
        <Typography variant="h4" marginBottom={2}>
          Red Flags
        </Typography>
        <MaterialReactTable
          muiTableBodyProps={{
            sx: {
              "& .MuiTableRow-root:hover .MuiTableCell-root": {
                backgroundColor: "inherit !important"
              }
            }
          }}
          muiTableBodyRowProps={({ row }) => ({
            style: row.index % 2 === 0 ? {} : { backgroundColor: "#f4f4f4" }
          })}
          columns={redFlagColumns}
          data={history}
          enableGlobalFilter
          enableFacetedValues
          enableColumnOrdering={enableOrdering}
          initialState={{
            density: "compact",
            pagination: { pageIndex: 0, pageSize: 30 },
            columnVisibility,
            columnFilters,
            showColumnFilters: columnFilters.flatMap((f) => f.value).length > 0
          }}
          state={{
            showSkeletons: loading,
            columnVisibility,
            columnFilters
          }}
          onColumnVisibilityChange={setColumnVisibility}
          onColumnFiltersChange={setColumnFilters}
          renderTopToolbarCustomActions={({ table }) => (
            <>
              <Actions dateRange={dateRange} setDateRange={setDateRange} table={table} />
              <Tooltip title="Column ordering">
                <IconButton
                  onClick={() => setEnableOrdering(!enableOrdering)}
                  aria-label="toggle column ordering"
                  style={{ position: "absolute", right: "200px" }}
                >
                  <Iconify icon="mdi:reorder-vertical" />
                </IconButton>
              </Tooltip>
            </>
          )}
        />
      </Container>
    </>
  );
}
